//THIS PAGE IS NOT IN USE ANYMORE. IT WAS REPLACED BY THE SINGLE IMAGE PAGE
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jsonImagesData from "../../assets/data/photos.json";
import NoImage from "../../components/SingleImage/noImage.component";
import SingleImage from "../../components/SingleImage/singleImage.component";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";
import { getSingleImageByID } from "../../models/images.model";
import { ImageType } from "../../types";
import "./image.scss";
import Koala from "../../assets/images/koala/koala-spinner.gif";

export type ImagePageProps = {
  onThemeChange: () => void;
};

const ImagePage: React.FC<ImagePageProps> = ({ onThemeChange }) => {
  const [boardId, setBoardId] = useState<string | null>(null);

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };

  const { id } = useParams(); // Read values passed on state
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [singleImage, setSingleImage] = useState<ImageType | null>(null);
  // get image from getSingleImageByID
  // useEffect(() => {
  //   if (id === undefined) return;

  //   setLoadingImage(true);
  //   const fetchImage = async () => {
  //     try {
  //       const jsonData = await getSingleImageByID(id);
  //       // if jsonData is a string, then there was an error
  //       if (typeof jsonData === "string") {
  //         console.log("error:", jsonData);
  //         setErrorMessage(jsonData);
  //       } else {
  //         console.log("success", jsonData);
  //         setSingleImage(jsonData);
  //       }
  //       setLoadingImage(false);
  //     } catch (error) {
  //       console.error("An error occurred while fetching data:", error);
  //       setLoadingImage(false);
  //     }
  //   };

  //   fetchImage();
  // }, []);

  // For bulk actions:
  const [showBulk, setShowBulk] = useState<boolean>(false);
  // state array containing the selected image ids from the Photo Container

  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);

  return (
    <div id="single_image_page" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          setShowBulk={setShowBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectType="none"
        />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      {/* This calls the photo container element from the components folder */}
      <div className="uk-width single-image-container">
        {loadingImage ? (
          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <img
              src={Koala}
              alt="Loading..."
              style={{ width: "150px", height: "150px" }}
            />
          </div>
        ) : (
          <>
            {/* {singleImage ? (
              <SingleImage
                singleImage={singleImage}
                showBulk={showBulk}
                setShowBulk={setShowBulk}
                selectedImageIds={selectedImageIds}
                setSelectedImageIds={setSelectedImageIds}
                selectedBulkImageIds={selectedBulkImageIds}
                setBulkSelectedImageIds={setBulkSelectedImageIds}

              />
            ) : (
              <NoImage error={errorMessage} />
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default ImagePage;
