import defaultImage from "../assets/images/backgrounds/login-figma.webp";
import { mattData } from "../services/API.service";

// TODO - We tested it sucessfully not fully implemented the API
// CALLS MATT'S API FOR IMAGE TO DISPLAY ON LOGIN PAGE
export async function dailyImage(): Promise<string> {
  try {
    // console.log("LOADING IMAGES");
    const response = await mattData<any>("/image/daily/get", "GET", {});
    // console.log("RESPONSE", response);
    if (response.status === 200) {
      // console.log("Status 200");
      if (
        response.data &&
        response.data !== null &&
        response.data.imageUrl !== null
      ) {
        // console.log("Image exists:\n", response.data.imageUrl);
        return response.data.imageUrl;
      } else {
        console.log("Image does not exist");
        return defaultImage;
      }
    } else {
      console.log("API path or image does not exist");
      return defaultImage;
    }
  } catch (error) {
    console.error("Error fetching image:", error);
    return defaultImage;
  }
}

export async function dailyVideo(): Promise<string | false> {
  const response = await mattData<any>("/video/daily/get", "GET", {});
  console.log("RESPONSE", response);
  if (response.status === 200) {
    if (
      response.data &&
      response.data !== null &&
      response.data.videoUrl !== null
    ) {
      return response.data.videoUrl;
    } else {
      return false;
    }
  }

  return false;
}
