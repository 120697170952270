import React, { useEffect, useReducer } from "react";
import {
  ClientType,
  CountryType,
  LanguageType,
  RoleType,
  TimezoneType,
} from "../../types";
import "./profileForm.scss";

export const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case "onInputChange":
      return {
        ...state,
        [action.field]: action.payload,
      };

    case "replaceState":
      return action.payload;

    default:
      return state;
  }
};

export const initialState = {
  name: "",
  surname: "",
  email: "",
  password: "",
  mobile: "",
  country_id: "",
  timezone_id: "",
  language_id: "",
  client_id: "",
  org_unit_id: "",
};

const ProfileForm: React.FC = () => {
  //   const [user, setUser] = React.useState<UserType>({} as UserType);
  const [loadingUser, setLoadingUser] = React.useState<boolean>(false);
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [countries, setCountries] = React.useState<CountryType[]>([]);
  const [timezones, setTimezones] = React.useState<TimezoneType[]>([]);
  const [languages, setLanguages] = React.useState<LanguageType[]>([]);
  const [roles, setroles] = React.useState<RoleType[]>([]);
  const [clients, setClients] = React.useState<ClientType[]>([]);
  const [loadingCountries, setLoadingCountries] =
    React.useState<boolean>(false);
  const [loadingTimezones, setLoadingTimezones] =
    React.useState<boolean>(false);
  const [loadingLanguages, setLoadingLanguages] =
    React.useState<boolean>(false);
  const [loadingRoles, setloadingRoles] = React.useState<boolean>(false);
  const [loadingClients, setLoadingClients] = React.useState<boolean>(false);

  // fetch user data from the database
  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoadingUser(true);
        const response = await fetch("/assets/data/user.json");
        const json = await response.json();

        // setUser(json);
        dispatch({ type: "replaceState", payload: json });
        setLoadingUser(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  // fetch countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoadingCountries(true);
        const response = await fetch("/assets/data/countries.json");
        const json = await response.json();
        setCountries(json.countries);
        setLoadingCountries(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountries();
  }, []);

  // fetch timezones
  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        setLoadingTimezones(true);
        const response = await fetch("/assets/data/timezones.json");
        const json = await response.json();
        setTimezones(json.timezones);
        setLoadingTimezones(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTimezones();
  }, []);

  // fetch languages
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        setLoadingLanguages(true);
        const response = await fetch("/assets/data/languages.json");
        const json = await response.json();
        setLanguages(json.languages);
        setLoadingLanguages(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLanguages();
  }, []);

  // fetch org units
  useEffect(() => {
    const fetchroles = async () => {
      try {
        setloadingRoles(true);
        const response = await fetch("/assets/data/org_units.json");
        const json = await response.json();
        setroles(json.org_units);
        setloadingRoles(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchroles();
  }, []);

  // fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoadingClients(true);
        const response = await fetch("/assets/data/clients.json");
        const json = await response.json();
        setClients(json.clients);
        setLoadingClients(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchClients();
  }, []);

  //handling inputs
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // console.log("Input: ", e.target.name, e.target.value);
    dispatch({
      type: "onInputChange",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log("Submit: ", formState);
    // TODO: send data to the server
  };

  return (
    <div id="profile_form" className="uk-grid">
      {/* if not loading */}
      {!loadingUser &&
      !loadingCountries &&
      !loadingLanguages &&
      !loadingTimezones &&
      !loadingRoles &&
      !loadingClients ? (
        <>
          <div className="uk-width-1-1 uk-width-1-2@m">
            <div className="uk-card-body">
              {/* name input */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Name
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-name"
                    type="text"
                    name="name"
                    value={formState.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* Surname */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Surname
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-surname"
                    type="text"
                    name="surname"
                    value={formState.sur_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* Email */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Email
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-email"
                    type="text"
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* Mobile */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Mobile
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-mobile"
                    type="text"
                    name="mobile"
                    value={formState.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* Country */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Country
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <select
                    className="uk-select"
                    id="form-stacked-select-country_id"
                    name="country_id"
                    value={formState.country_id}
                    onChange={handleInputChange}
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Timezone */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Timezone
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <select
                    className="uk-select"
                    id="form-stacked-select-timezone_id"
                    name="timezone_id"
                    value={formState.timezone_id}
                    onChange={handleInputChange}
                  >
                    {timezones.map((timezone) => (
                      <option key={timezone.id} value={timezone.id}>
                        {/* int to sting */}({timezone.offset > 0 && "+"}
                        {timezone.offset.toString()} UTC) {timezone.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Language */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Language
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <select
                    className="uk-select"
                    id="form-stacked-select-language_id"
                    name="language_id"
                    value={formState.language_id}
                    onChange={handleInputChange}
                  >
                    {languages.map((language) => (
                      <option key={language.iso_code} value={language.iso_code}>
                        {language.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Role */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Role
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-role"
                    type="text"
                    name="Role"
                    value={
                      roles.find((role) => {
                        return (
                          role.name.toLowerCase ===
                          formState.role_id.toLowerCase
                        );
                      })?.name
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {/* Client */}
              <div className="uk-margin uk-grid">
                <label
                  className="uk-form-label uk-width-1-1 uk-width-1-3@m uk-width-1-6@l"
                  htmlFor="form-stacked-text"
                >
                  Projects
                </label>
                <div className="uk-form-controls uk-width-1-1 uk-width-2-3@m uk-width-5-6@l">
                  <input
                    className="uk-input"
                    id="form-stacked-text-client"
                    type="text"
                    name="client"
                    value={
                      clients.find((client) => {
                        return (
                          client.name.toLowerCase ===
                          formState.client_id.toLowerCase
                        );
                      })?.name
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-1-2@m">B</div>
          <div className="uk-width-1-1">
            <button
              className="uk-button uk-button-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        // if loading
        <div className="uk-width-1-1 uk-width-1-2@m">Loading...</div>
      )}
    </div>
  );
};

export default ProfileForm;
