import { AllBoards, BoardType, HeaderBoard, ImageType } from "../types";
import { apiFetcher } from "../services/API.service";
import Swal from "sweetalert2";

function isImage(obj: any): obj is ImageType {
  const validID = typeof obj.id === "string";
  const validTitle = typeof obj.title === "string";
  const validDescription = typeof obj.description === "string";
  const validKeywords = Array.isArray(obj.keywords);
  const validOcr = typeof obj.ocr === "string";
  const validWidth = typeof obj.width === "number";
  const validHeight = typeof obj.height === "number";
  const validSource = typeof obj.source === "string";

  const AllValid =
    validID &&
    validTitle &&
    validDescription &&
    validKeywords &&
    validOcr &&
    // validWidth &&
    // validHeight &&
    validSource;

  // if (!AllValid) {
  //   console.log(obj);
  //   let errors = [];

  //   if (!validID) {
  //     if (!obj.hasOwnProperty("id")) {
  //       errors.push("missing id");
  //       let displayMessage = `<strong>Please contact support</strong><br><br>`;

  //       Swal.fire({
  //         icon: "error",
  //         title: `Invalid Image`,
  //         html: displayMessage + errors.join("<br>"),
  //         showConfirmButton: true,
  //         confirmButtonColor: "#3085d6",
  //       });
  //       return false;
  //     } else {
  //       errors.push(`Invalid type for id`);
  //     }
  //   }

  //   if (!validTitle) {
  //     if (!obj.hasOwnProperty("title")) {
  //       errors.push("missing title");
  //     } else {
  //       errors.push(`Invalid type for title`);
  //     }
  //   }

  //   if (!validDescription) {
  //     if (!obj.hasOwnProperty("description")) {
  //       errors.push("missing description");
  //     } else {
  //       errors.push(`Invalid type for description`);
  //     }
  //   }

  //   if (!validKeywords) {
  //     if (!obj.hasOwnProperty("keywords")) {
  //       errors.push("missing keywords");
  //     } else {
  //       errors.push(`Invalid type for keywords`);
  //     }
  //   }

  //   if (!validOcr) {
  //     if (!obj.hasOwnProperty("ocr")) {
  //       errors.push("missing ocr");
  //     } else {
  //       errors.push(`Invalid type for ocr`);
  //     }
  //   }

  //   // if (!validWidth) {
  //   //   if (!obj.hasOwnProperty("width")) {
  //   //     errors.push("missing width");
  //   //   } else {
  //   //     errors.push(`Invalid type for width`);
  //   //   }
  //   // }

  //   // if (!validHeight) {
  //   //   if (!obj.hasOwnProperty("height")) {
  //   //     errors.push("missing height");
  //   //   } else {
  //   //     errors.push(`Invalid type for height`);
  //   //   }
  //   // }

  //   if (!validSource) {
  //     if (!obj.hasOwnProperty("source")) {
  //       errors.push("missing source");
  //     } else {
  //       errors.push(`Invalid type for source`);
  //     }
  //   }

  //   console.log(`Invalid imageID: ${obj.id}`);

  //   errors.forEach((error) => {
  //     // remove first empty element
  //     if (error !== "") {
  //       console.log(error);
  //     }
  //   });

  //   let displayMessage = `<strong>Please contact support</strong><br><br>`;
  //   displayMessage += `ImageID: ${obj.id}<br>`;

  //   Swal.fire({
  //     icon: "error",
  //     title: `Invalid Image`,
  //     html: displayMessage + errors.join("<br>"),
  //     showConfirmButton: true,
  //     confirmButtonColor: "#3085d6",
  //   });
  // }

  return true;
}

export type CreateBoard = {
  message: {
    message: string;
    timestamp: string;
  };
  boardID: number;
};

// create board
export async function createBoard(
  boardName: string,
  clientID: string,
  imageIDs?: string[]
): Promise<string | CreateBoard> {
  try {
    const response = await apiFetcher<any>("/board/create", "POST", {
      body: {
        imageBoards: { boardName: boardName, boardImages: imageIDs },
        clientID: clientID,
      },
    });

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return response.data as CreateBoard;
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return "Data format is incorrect";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    if (response.status === 409) {
      return "Board name already exists. Please choose another name";
    }

    console.log("Failed to create board");
    return "Failed to create board";
  } catch (error) {
    console.error("An error occurred while creating board:", error);
    return "An error occurred while creating board";
  }
}

// update board name
export async function boardUpdate(
  boardID: string,
  boardName: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<any>("/board/update", "POST", {
      body: {
        boardID: boardID,
        boardName: boardName,
      },
    });

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return {
        rStatus: "success",
        rMessage: "Board Name has been changed",
      };
    } else {
      console.log("An error occurred while updating the board name:");
      Swal.fire({
        icon: "error",
        title: `There was an error updating the board name`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return {
        rStatus: "error",
        rMessage: "Error has occurred updating board name",
      };
    }
  } catch (error) {
    console.error("An error occurred while updating the board name:", error);
    Swal.fire({
      icon: "error",
      title: `There was an error updating the board name`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return {
      rStatus: "error",
      rMessage: "Error has occurred updating board name",
    };
  }
}

// Delete Board
export async function boardDelete(boardID: string): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<any>("/board/delete", "POST", {
      body: {
        boardID: boardID,
      },
    });

    if (response.status === 200 && response.data !== null) {
      return {
        rStatus: "success",
        rMessage: "Board Has Been Deleted",
      };
    } else {
      console.log("An error occurred while attempting to delete the board:");
      Swal.fire({
        icon: "error",
        title: `There was an error deleting the board`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return {
        rStatus: "error",
        rMessage: "Error has occurred deleting the board",
      };
    }
  } catch (error) {
    console.error("An error occurred while deleting the board:", error);
    Swal.fire({
      icon: "error",
      title: `There was an error deleting the board`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return {
      rStatus: "error",
      rMessage: "Error has occurred deleting the board",
    };
  }
}

export type AllBoardsResponse = {
  boards: AllBoards;
  expires_at: number;
};

//  get all boards
export async function getAllBoards(): Promise<AllBoardsResponse | string> {
  try {
    const response = await apiFetcher<any>("/board/read/researcherID", "POST", {
      body: {},
    });

    console.log(response.status);
    console.log(response.data);

    if (
      response.status === 200 &&
      response.data !== null &&
      response.data.boards !== null
    ) {
      // console.log(response.data);

      if (!response.data) {
        console.log("No boards object found");
        return "No boards object found";
      }

      //------Positive responses------//
      if (!response.data || !Array.isArray(response.data.boards)) {
        console.log("Data is not an array");
        return "Data is not an array";
      }
      // Positive response
      if (response.data.boards.every((obj: any) => isImage(obj))) {
        console.log("Valid image data received");
        return response.data as AllBoardsResponse;
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }
    }

    if (response.status === 204) {
      console.log("No boards found");
      return { boards: [], expires_at: 0 } as unknown as AllBoardsResponse;
    }

    //-------Failed responses-------//
    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    } else {
      console.log("Failed to fetch all boards");
      return "Failed to fetch all boards";
    }
  } catch (error) {
    console.error("An error occurred while fetching boards:", error);
    return "An error occurred while fetching boards";
  }
}

//Share board
export async function boardInvite(
  boardID: number,
  emailArray: string[]
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/board/share", "POST", {
      body: {
        boardID: boardID,
        emailArray: emailArray,
      },
    });

    if (response.status === 200) {
      return "success";
    } else if (response.status === 409) {
      return "Board name already exists. Please choose another name";
    } else if (response.status === 400) {
      return "Data format is incorrect";
    } else if (response.status === 404) {
      return "Route not found";
    } else {
      return "Failed to share board";
    }
  } catch (error) {
    console.error("An error occurred while sharing board:", error);
    return "An error occurred while sharing board";
  }
}

// Function to copy images to a board
export async function copyImages({
  imageIDs,
  currentBoardID,
  newBoardID,
}: {
  imageIDs: string[];
  currentBoardID: string;
  newBoardID: string;
}): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  console.log("HIT copyImages");
  try {
    // upload image details to api
    const apiUploadDetailsResponse = await apiFetcher<any>(
      "/board/update/images",
      "POST",
      {
        body: {
          currentBoardID: currentBoardID,
          newBoardID: newBoardID,
          imageIDs: imageIDs,
        },
      }
    );

    console.log(apiUploadDetailsResponse);

    if (
      apiUploadDetailsResponse.status === 200 ||
      apiUploadDetailsResponse.status === 202
    ) {
      return {
        rStatus: "success",
        rMessage: "Images copied successfully",
      };
    } else {
      console.log("Failed to copy images");
      return {
        rStatus: "error",
        rMessage: "Failed to copy images",
      };
    }
  } catch (error) {
    console.error("An error occurred while copying images:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while copying images",
    };
  }
}
