import React, { useEffect, useState } from "react";
import BoardsContainer from "../../components/Boards/boardsContainer.component";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";

export type ViewAllBoardPageProps = {
  onThemeChange: () => void;
};

const ViewAllBoardsPage: React.FC<ViewAllBoardPageProps> = ({
  onThemeChange,
}) => {
  const [boardId, setBoardId] = useState<string | null>(null);

  // Show the bulk icon in the header depending on whether photos are selected in Photo Container
  const [showBulk, setShowBulk] = useState<boolean>(false);

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };

  return (
    <div id="dashboard" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          selectType="board"
        />
      </div>
      {/* This calls the boards container element from the components folder */}
      <div className="uk-width-1-1@s">
        <BoardsContainer boardId={boardId} showBulk={showBulk} />
      </div>
    </div>
  );
};

export default ViewAllBoardsPage;
