import React from "react";
import { useNavigate } from "react-router-dom";
import { ImageType } from "../../types";
// pin icon
import EditIcon from "../../assets/images/icons/editImage.svg";

interface PploadImageMultipleView {
  images: ImageType[];
  setWindowToDisplay: React.Dispatch<React.SetStateAction<string>>;
  setCurrentImage: React.Dispatch<React.SetStateAction<number | null>>;
}

const UploadImageMultipleView: React.FC<PploadImageMultipleView> = ({
  images,
  setWindowToDisplay,
  setCurrentImage,
}) => {
  const navigate = useNavigate();
  const handleImageClick = (imageId: string) => {
    // console.log("imageId:", imageId);
    setCurrentImage(parseInt(imageId));
    setWindowToDisplay("edit");
  };

  const handleRemainingImagesSubmit = () => {
    // submit the remaining images
    // TODO: add the image to the database
    // console.log("all images submitted");
    navigate(`/dashboard`);
  };

  return (
    <div id="upload_image_multiple_view" className="uk-width-1-1@s">
      <div id="images_container" className="uk-width-1-1@s uk-padding">
        <div id="images_grid" className="uk-grid">
          {/* submit at the bottom  */}

          {images &&
            Array.from(images).map((image, index) => (
              <div
                key={index}
                id={`img_${image.id}`}
                className="image_div_container uk-width-1-2 uk-width-1-4@m uk-width-1-6@l"
              >
                <div
                  className="uk-card uk-card-default uk-card-small uk-margin-medium-bottom"
                  onClick={() => handleImageClick(image.id)}
                  style={{
                    backgroundImage: `url(${image.source})`,
                  }}
                >
                  {/* <img src={image.source} alt="img" /> */}
                  <img src={EditIcon} alt="pin" className="edit_icon" />
                </div>
              </div>
            ))}
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-center">
              <button
                className="btn"
                onClick={() => handleRemainingImagesSubmit()}
              >
                Submit without editing
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImageMultipleView;
