import { useParams } from "react-router-dom";
import LoginLoading from "../../components/LoginLoading/loginLoading";
import { JWT } from "../../types";

import { useEffect } from "react";
import {
  isTokenExpired,
  refreshTokenCheckRedirect,
  requestAndSetTokens,
} from "../../utilities/tokenHandler.util";
import "./loggingIn.scss";
import Koala from "../../assets/images/koala/koala-spinner.gif";

const LoggingInPage = () => {
  const { jwt } = useParams();
  // // if jwt and encoded is null or undefined or empty string, redirect to login
  // useEffect(() => {
  //   if (!jwt) {
  //     //after 3 seconds, redirect to login
  //     // setTimeout(() => {
  //     window.location.href = "/login";
  //     // }, 3000);
  //     console.log("jwt is null");
  //   }
  // }, []);

  // Use the jwt to request refresh and auth and set it in local storage
  useEffect(() => {
    const fetchData = async () => {
      if (jwt && isTokenExpired(jwt) === false) {
        // console.log("jwt is not expired");
        const newJwt: JWT = {
          refreshToken: jwt,
        };
        // Request and Set the JWTs in local storage if the request was successful and is available
        await requestAndSetTokens(newJwt);
        // Once fetchMfaJWT is done and local storage is set, execute refreshTokenCheckRedirect
        refreshTokenCheckRedirect();
      } else {
        console.log("jwt is expired");
        window.location.href = "/login";
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {jwt ? (
        <LoginLoading />
      ) : (
        <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          <img
            src={Koala}
            alt="Loading..."
            style={{ width: "150px", height: "150px" }}
          />
          <h1>Logging In</h1>
        </div>
      )}
    </>
  );
};

export default LoggingInPage;
