import React, { ChangeEvent, useState } from "react";
import { requestEmail } from "../../models/auth.model";
import { isEmailValid } from "../../utilities/InputTesting.util";
import { encodeEmailMl } from "../../utilities/encoding.util";

export type MagicLinkProps = {
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  magicLinkSent: boolean;
  setMagicLinkSent: React.Dispatch<React.SetStateAction<boolean>>;
};

const MagicLink: React.FC<MagicLinkProps> = ({
  handleEmailChange,
  email,
  setEmail,
  magicLinkSent,
  setMagicLinkSent,
}) => {
  // const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // typescript boolean usestate
  const [busySubmitting, setBusySubmitting] = useState(false);

  const setError = (message: string) => {
    setErrorMessage(message);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleLogin = async () => {
    // console.log("handleLogin");
    // if email is different than regex, set error message and return
    const isEmailValidCheck = await isEmailValid(email);

    if (isEmailValidCheck === false) {
      setError("Please enter a valid email address.");
      return;
    }

    setBusySubmitting(true);
    setError("");

    // Encode the email and make the request
    const encodedEmail = await encodeEmailMl(email);
    if (encodedEmail) {
      const emailResponse = await requestEmail(encodedEmail);
      if (emailResponse === 202) {
        setMagicLinkSent(true);
        clearError();
      } else if (emailResponse === 400) {
        console.log("bad request");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("400 please contact support.");
      } else if (emailResponse === 404) {
        console.log("login not found");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Invalid email.");
      } else if (emailResponse === 403) {
        console.log("Email not found");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Email not found.");
      } else {
        console.log("login failed");
        setMagicLinkSent(false);
        setBusySubmitting(false);
        setError("Server error. Please try again.");
      }
    }

    if ("caches" in window) {
      // console.log(caches)
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Unregister the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  };

  // const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
  //     setEmail(e.target.value);
  // };

  // Submit if enter key is pressed
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  // This will handle the back button click after magicLink submit button
  function handleBack() {
    setBusySubmitting(false);
    setMagicLinkSent(false);
  }

  return (
    <div>
      {/* Check if the magicLink was sent if not: */}
      {!magicLinkSent ? (
        <>
          <div className="uk-margin login_input_section">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Email:
            </label>
            <input
              className="uk-input"
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="johnd@mdi-global.com"
            />
          </div>
          <div className="uk-margin-top">
            <button
              className="uk-button btn_primary login_btn"
              onClick={handleLogin}
              // if busySubmitting disable button
              disabled={busySubmitting}
            >
              {!busySubmitting ? `Submit` : `Submitting...`}
            </button>
            <span
              className={`uk-margin-small-left span_incorrect  ${
                errorMessage ? `shown` : `hidden`
              }`}
            >
              {errorMessage}
            </span>
          </div>
        </>
      ) : (
        /* Check if the magicLink was sent if it was: */
        <>
          <div className="uk-margin uk-flex uk-flex-center login_input_section">
            <label className="fontSizeSent">
              The link has been sent please check your email:
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-center login_input_section">
            <label>If you did not receive the email try again.</label>
          </div>

          <div className="uk-margin-top">
            <button
              className="uk-button btn_primary login_btn"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MagicLink;
