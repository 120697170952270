import { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/images/icons/upload.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as BackIcon } from "../../../assets/images/icons/back.svg";
import "../uploadImage.scss";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import localforage from "localforage";
import { sendReimportRequest } from "../../../models/wsc.model";
import { ClientType, ClientResponse } from "../../../types";

interface WSCImportsProps {
  setWindowToDisplay: React.Dispatch<React.SetStateAction<string>>;
  openWscAttributeModal: () => void;
}

const WSCImports: React.FC<WSCImportsProps> = ({
  setWindowToDisplay,
  openWscAttributeModal,
}) => {
  const { clientID } = useParams<{ clientID: string }>();

  const getClientID = async () => {
    const allClients: string | null = await localforage.getItem("allClients");

    if (typeof allClients === "string") {
      try {
        const arrayOfClients: ClientResponse = JSON.parse(allClients);

        if (clientID && arrayOfClients.clients) {
          const client = arrayOfClients.clients.find(
            (client: ClientType) => client.client_id.toString() === clientID
          );
          return client?.name;
        }
      } catch (error) {
        console.log("Error parsing client data", error);
      }
    } else {
      return "Client not found";
    }
  };
  const openReimportModal = async () => {
    const clientName = await getClientID();
    if (clientName === "Client not found") {
      Swal.fire({
        title: "Client not found",
        text: "Please select a client from the dashboard",
        icon: "error",
      });
      return;
    }
    Swal.fire({
      title: "Re-Import Images from WSC",
      html: `Confirm your action. Please be aware that proceeding with this request will require substantial processing resources and may incur significant costs. To proceed, please enter the client name <strong>"${clientName}"</strong> to confirm.`,
      input: "text",
      inputPlaceholder: "Client Name",
      showCancelButton: true,
      confirmButtonText: "Reimport",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter the client name";
        }
        if (value !== clientName) {
          return "Client name does not match, Please try again";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Re-Import requested",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const reimportRequest = async () => {
          const reimportRequest = await sendReimportRequest(clientID!);
          Swal.close();

          if (reimportRequest === "success") {
            Swal.fire({
              title: "Re-import request sent",
              text: "Thank you. The re-import process is now running in the background. We appreciate your patience.",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Re-Import request failed",
              text: "Please try again",
              icon: "error",
            });
          }
        };
        reimportRequest();
      }
    });
  };

  return (
    <div className="import-wsc">
      <div
        id="upload_image_page_component_container"
        className="uk-flex uk-grid no_image form_container"
      >
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Manage Attributes</div>
              <EditIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => setWindowToDisplay("wscEdit")}
                >
                  Manage
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Re-Import Images from WSC</div>
              <UploadIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => openReimportModal()}
                >
                  Re-import
                </label>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WSCImports;
