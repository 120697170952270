import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";
import { useParams } from "react-router-dom";
import MDILogo from "../../assets/images/logo/mdi-logo-final.webp";
import { ImageType, AttributeToggle } from "../../types";
import "./sharedBoards.scss";
import PhotosContainer from "../../components/Photos/photosContainer.component";
import localforage from "localforage";
import Swal from "sweetalert2";
import SidePanelModal from "../../components/SideModal/sideModalPanel.component";

const SharedBoardPage: React.FC = () => {
  const { sharedUrl } = useParams<{ sharedUrl: string }>();
  const [boardID, setBoardID] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showBulk, setShowBulk] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, Record<string, string[]>>
  >({});
  const [allImages, setAllImages] = useState<ImageType[]>([]);
  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [andOrToggle, setAndOrToggle] = useState<AttributeToggle>("MIX");
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
  const [singleImageID, setSingleImageID] = useState<string | null>(null);
  const [showSingleImageModal, setShowSingleImageModal] =
    useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>("");
  const [gptSearch, setGptSearch] = useState<string>("");
  const [btnSearchClicked, setBtnSearchClicked] = useState<boolean>(false);
  const [selectedSearchType, setSelectedSearchType] = useState<
    "smart" | "search" | "attributes"
  >("attributes");
  const [selectedImage, setSelectedImage] = useState<ImageType | null>(null);

  useEffect(() => {
    //lets check for local forage first
    //We need to split the shareToken for the first two digits which is the boardID where the : is the separator
    console.log(sharedUrl);
    if (sharedUrl === undefined) {
      Swal.fire({
        icon: "error",
        title: "Invalid URL",
        text: "The URL is invalid",
      });
      return;
    }

    //set In localForage the boardID and shareToken
    const setForage = async () => {
      let splitShareToken = sharedUrl.split(":");
      let boardIDUrl = splitShareToken[0];
      let shareToken = splitShareToken[1];

      console.log("Setting forage");
      await localforage.setItem("boardID", boardIDUrl);
      await localforage.setItem("sharedToken", shareToken);
      setBoardID(boardIDUrl);
    };
    setForage();
  }, [sharedUrl]);

  return (
    <div className="shared-board-page uk-grid">
      <div className="uk-width-1-1">
        <div className="uk-text-center uk-width-1-5 navbar-shared">
          <img
            className="logoSize uk-padding-small"
            src={MDILogo}
            alt="MDI Logo"
          />
        </div>
      </div>
      <div className="uk-width-1-3@s uk-width-1-5@m">
        <Sidebar
          searchOptions={["search", "attributes"]}
          clientID={sharedUrl!}
          selectType="shared"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setSearchWord={setSearchWord}
          selectedSearchType={selectedSearchType}
          setSelectedSearchType={setSelectedSearchType}
          setGptSearch={setGptSearch}
          btnSearchClicked={btnSearchClicked}
          setBtnSearchClicked={setBtnSearchClicked}
          andOrToggle={andOrToggle}
          setAndOrToggle={setAndOrToggle}
        />
      </div>
      <div className="uk-width-2-3@s uk-width-4-5@m inner_container">
        <PhotosContainer
          selectType="shared"
          clientID={sharedUrl!}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedFilters={selectedFilters}
          searchWord={searchWord}
          selectedSearchType={selectedSearchType}
          gptSearch={gptSearch}
          btnSearchClicked={btnSearchClicked}
          setSingleImageID={setSingleImageID}
          setShowSingleImageModal={setShowSingleImageModal}
          andOrToggle={andOrToggle}
        />
      </div>
      {showSingleImageModal && singleImageID !== null && boardID && (
        <SidePanelModal
          sourceID={boardID}
          showModal={showSingleImageModal}
          setShowModal={() => setShowSingleImageModal(false)}
          singleImageID={singleImageID}
          setSingleImageID={setSingleImageID}
          imageSource="shared"
          location="right"
          contentType="singleImage"
          onClose={() => setShowSingleImageModal(false)}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
        />
      )}
    </div>
  );
};

export default SharedBoardPage;
