import localforage from "localforage";
import { fetchAllClients } from "../models/client.model";

async function fetchAndStoreClients() {
  const getClients = await fetchAllClients();
  if (typeof getClients !== "string" && Array.isArray(getClients.clients)) {
    //We will store the data in local storage
    localforage.setItem("allClients", JSON.stringify(getClients));
    return getClients;
  } else {
    return "Failed to fetch clients";
  }
}

//This is a util function which we will use to either fetch data from the server or from local storage (local forage).Here we will check for expiry as well to make sure our thumbnails are loaded
export async function loadAllClients() {
  //We will first check if the data is available in local storage
  let getLocalClients: string | null = await localforage.getItem("allClients");

  if (typeof getLocalClients === "string" && getLocalClients !== null) {
    //Cool we have data now we want to check if the data is expired
    let localClients = JSON.parse(getLocalClients);
    //Check if the data is expired
    //expiry time is a epoch time of 47 hours we want to check if is equal or less to that time
    let expiryTime = localClients.expires_at;

    if (expiryTime < new Date().getTime()) {
      //Data is expired so we will fetch it again
      let newClients = await fetchAndStoreClients();
      if (typeof newClients !== "string" && Array.isArray(newClients.clients)) {
        console.log("Data is expired and we fetched it again");
        return newClients;
      } else {
        return "Failed to fetch clients";
      }
    } else {
      //Data is not expired so we will return the data
      console.log("Data is not expired");
      return localClients;
    }
  } else {
    //We dont have data and we should fetch it again
    let newClients = await fetchAndStoreClients();
    if (typeof newClients !== "string" && Array.isArray(newClients.clients)) {
      console.log("WE dont need to fetch again");
      return newClients;
    } else {
      return "Failed to fetch clients";
    }
  }
}
