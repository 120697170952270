import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SearchResult from "../../components/SearchResult/searchResult.component";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";

export type ResultPageProps = {
  onThemeChange: () => void;
};

const ResultPage: React.FC<ResultPageProps> = ({ onThemeChange }) => {
  const [boardId, setBoardId] = useState<string | null>(null);
  const [showBulk, setShowBulk] = useState<boolean>(false);

  // state array containing the selected image ids from the Photo Container
  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);

  const { searchType, searchTags } = useParams();

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };

  return (
    <div id="dashboard" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          setShowBulk={setShowBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectType="none"
        />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width-1-3@s uk-width-1-5@m">{/* <Sidebar /> */}</div>
      {/* This calls the photos container element from the components folder */}
      <div className="uk-width-2-3@s uk-width-4-5@m">
        <SearchResult
          searchType={searchType}
          searchTags={searchTags}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
        />
      </div>
    </div>
  );
};

export default ResultPage;
