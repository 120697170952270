import React, { ChangeEvent, useEffect, useState } from "react";
import defaultImage from "../../assets/images/backgrounds/login-figma.webp";
import MDILogo from "../../assets/images/logo/mdi-logo-final.webp";
import TwoFactor from "../../components/2FA/two-factor.component";
import MagicLink from "../../components/MagicLink/magic-link.component";
import ToggleSwitch from "../../components/ToggleSwitch/toggleSwitch.component";
import { dailyImage, dailyVideo } from "../../models/daily.image.model";
import "./login.scss";
import localforage from "localforage";
import clearSiteData from "../../utilities/clear.util";

function LoginPage() {
  // This will check the toggle state of the toggle switch. 2FA or Magiclink
  const [toggleState, setToggleState] = useState(false);
  // Persisting Email between MagicLink and 2FA
  const [email, setEmail] = useState("");
  // Checks if magic link is sent
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  // Sets the state for background image for login, will default to a static image.
  const [backgroundImageURL, setBackgroundImageURL] =
    useState<string>(defaultImage);

  const [backgroundVideoURL, setBackgroundVideoURL] = useState<string | null>(
    null
  );

  // LOGIN CODE STARTS HERE:

  // TODO - We tested it sucessfully not fully implemented the API
  // Gets the image for login background from daily.image.model.ts
  useEffect(() => {
    const fetchImage = async () => {
      try {
        // set image to the image that was called in dailyImage model
        const image = await dailyImage();
        console.log("Calling model with image:", image);
        setBackgroundImageURL(image);
      } catch (error) {
        console.error("Error calling model", error);
        // No need to set defaultImage here as it's handled in dailyImage
      }
    };

    fetchImage();
  }, []);

  // get background video from daily.image.model.ts
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        // set video to the video that was called in dailyVideo model
        const video = await dailyVideo();
        // console.log("Calling model with video:", video);
        if (!video) return;
        console.log("video", video);
        setBackgroundVideoURL(video);
      } catch (error) {
        console.error("Error calling model", error);
        // No need to set defaultImage here as it's handled in dailyVideo
      }
    };

    fetchVideo();
  }, []);

  // When a user initially comes to the LoginPage it will log them out and flush the dns and service worker cache
  useEffect(() => {
    // Log the user out
    // Flush the service worker cache
    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker.getRegistrations().then((registrations) => {
    //     for (let registration of registrations) {
    //       registration.unregister();
    //     }
    //   });
    // }
    try {
      console.log("trying to clear site data: ");
      clearSiteData();
    } catch (error) {
      console.log("could not clear site data:", error);
    }

    // console.log("test on login page 2", localStorage.getItem("pending_user_email"))
  }, []);

  // Function to toggle between MagicLink and 2FA components
  const handleToggleSwitch = (checked: boolean) => {
    setToggleState(checked);
  };

  // Function to handle email change between 2fa and magic, also makes it lowercase
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    // lowercase
    setEmail(e.target.value.toLowerCase());
  };

  // useeffect that clears local storage on login page
  useEffect(() => {
    localStorage.removeItem("pending_user_email");
    localStorage.removeItem("refresh-token");
    localStorage.clear();
    localforage.clear();
  }, []);

  return (
    <div
      style={{ backgroundImage: `url(${backgroundImageURL})` }}
      id="page_login"
    >
      {backgroundVideoURL && (
        <video muted loop id="login_background_vid" autoPlay>
          <source src={backgroundVideoURL} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      )}

      <img id="mdi_logo" src={MDILogo} alt="MDI Logo" />
      <div className="uk-container uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
        <div
          id="login_card"
          className="uk-card uk-card-default uk-width-2xlarge uk-box-shadow-large"
        >
          <div className="uk-card-body">
            {/* This will check if magicLink has already been submitted by user */}
            {!magicLinkSent ? (
              <>
                <h3 className="smallHeaderFont uk-card-title uk-text-center">
                  {" "}
                  Smart Image Management{" "}
                </h3>
                {/* This will hold the toggle switch for 2fa or magicLink*/}
                <div
                  id="login_toggler"
                  className="uk-button-group uk-width-1-1 uk-margin"
                >
                  {/* <div id="login_toggler_inner">
                    <ToggleSwitch
                      id="toggle_login"
                      checked={toggleState}
                      onChange={handleToggleSwitch}
                      optionLabels={["2FA", "Magic Link"]}
                      disabled={false}
                    />
                  </div> */}
                </div>
              </>
            ) : null}
            {/* This will check the state of toggle and display the appropriate component*/}
            {!toggleState ? (
              <MagicLink
                handleEmailChange={handleEmailChange}
                email={email}
                setEmail={setEmail}
                setMagicLinkSent={setMagicLinkSent}
                magicLinkSent={magicLinkSent}
              />
            ) : (
              <TwoFactor
                handleEmailChange={handleEmailChange}
                email={email}
                setEmail={setEmail}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
