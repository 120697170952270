import { useNavigate } from "react-router-dom";
import "./CarouselView.scss";
import { AllBoards, BoardType, ClientType, ViewLayout } from "../../types";
import Swal from "sweetalert2";
import { boardInvite } from "../../models/board.model";
import { ReactComponent as ShareIcon } from "../../assets/images/icons/share.svg";
import placeholderImage from "../../assets/images/koala/KoalaTemp.png"; // Verify the path is correct
import { useEffect, useState } from "react";

export type AllBoards1 = {
  id: string;
  title: string;
  coverImage: string;
  active: boolean;
  clientID: string;
};

type CarouselViewProps = {
  source: ClientType[] | AllBoards[];
  sourceOrigin: "client" | "board";
  viewLayout: ViewLayout;
};

const CarouselView: React.FC<CarouselViewProps> = ({
  source,
  sourceOrigin,
  viewLayout,
}) => {
  const navigate = useNavigate();
  //================Share Function to handle card click================
  const shareBoard = async (e: React.MouseEvent<SVGSVGElement>, id: string) => {
    console.log("shareBoard");
    e.stopPropagation();
    Swal.fire({
      title: "Sharing board",
      text: `Share this board with others by adding their email address below. If you want to share with multiple people, separate their email addresses with a comma.`,
      input: "email",
      inputPlaceholder: "Email address",
      confirmButtonText: "Send invitation",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        //Email regex
        const regex = /\S+@\S+\.\S+/;
        if (value.includes(",")) {
          const emails = value.split(",");
          for (let email of emails) {
            if (!regex.test(email.trim())) {
              return "Please enter a valid email address!";
            }
          }
        } else {
          if (!regex.test(value)) {
            return "Please enter a valid email address!";
          }
        }
        return null;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Sharing board",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const sendInvitation = await boardInvite(parseInt(id), [result.value]);

        Swal.close(); // Close loading Swal

        if (sendInvitation === "success") {
          Swal.fire(
            "Board shared",
            "You've successfully shared the board",
            "success"
          );
        } else {
          Swal.fire(
            "Error sharing board",
            "An error occurred while sharing the board. Please try again.",
            "error"
          );
        }
      }
    });
  };

  const handleCardClick = (id: string, clientID?: string) => {
    if (sourceOrigin === "client") {
      navigate("/client/" + id);
    } else {
      navigate(`/${clientID}/board/` + id);
    }
  };
  const tempSource = [...source] as AllBoards[] | ClientType[];

  function chunkArray(myArray: AllBoards[] | ClientType[], chunk_size: number) {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  // Number of items per page which will depend on the on the screen size
  const [amountPerPage, setAmountPerPage] = useState(10);

  useEffect(() => {
    if (viewLayout === "carousel") {
      if (window.innerWidth <= 280) {
        setAmountPerPage(3);
      } else if (window.innerWidth <= 480) {
        setAmountPerPage(4);
      } else if (window.innerWidth <= 788) {
        setAmountPerPage(6);
      } else if (window.innerWidth <= 1024) {
        setAmountPerPage(7);
      } else if (window.innerWidth <= 1200) {
        setAmountPerPage(8);
      } else {
        setAmountPerPage(9);
      }
    }
  }, [viewLayout]); //

  const chunkedArray = chunkArray(tempSource, amountPerPage);

  return (
    <>
      {source.length === 0 ? (
        <div className="no-clients">
          <h2>
            No {sourceOrigin === "client" ? "projects" : "boards"} were found
          </h2>
        </div>
      ) : (
        <div
          className={
            viewLayout === "carousel"
              ? `carousel-container`
              : viewLayout === "grid"
              ? "grid-container"
              : "list-container"
          }
        >
          {viewLayout !== "carousel" ? (
            <div className="items">
              {source.map((item, index) => {
                if (sourceOrigin === "client") {
                  const clientItem = item as ClientType;
                  return (
                    <div
                      className="item"
                      key={`client_id_container_${clientItem.client_id}`}
                      style={{
                        backgroundImage: `url(${
                          clientItem.logo ? clientItem.logo : placeholderImage
                        })`,
                      }}
                    >
                      <div
                        className="image-overlay"
                        onClick={() => handleCardClick(clientItem.client_id)}
                      ></div>
                      <div className="item-title">
                        <span className="footer-title">{clientItem.name}</span>
                      </div>
                    </div>
                  );
                } else {
                  const boardItem = item as AllBoards;
                  return (
                    <div
                      className="item"
                      key={`board_id_container_${boardItem.id}`}
                      style={{
                        backgroundImage: `url(${
                          boardItem.coverImage
                            ? boardItem.coverImage
                            : placeholderImage
                        })`,
                      }}
                    >
                      <ShareIcon
                        className="share-overlay share-icon"
                        onClick={(e) => shareBoard(e, boardItem.id)}
                      />
                      <div
                        className="image-overlay"
                        onClick={() =>
                          handleCardClick(boardItem.id, boardItem.clientID)
                        }
                      ></div>
                      <div className="item-title">
                        <span className="footer-title">{boardItem.title}</span>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div className="all-items">
              {chunkedArray.map((chunk, chunkIndex) => (
                <div className="item-row" key={chunkIndex}>
                  <div className="items">
                    {chunk.map((item, index) => {
                      if (sourceOrigin === "client") {
                        const clientItem = item as ClientType;
                        return (
                          <div className="item">
                            <div
                              className="item"
                              key={`client_id_container_${clientItem.client_id}`}
                              style={{
                                backgroundImage: `url(${
                                  clientItem.logo
                                    ? clientItem.logo
                                    : placeholderImage
                                })`,
                              }}
                            >
                              <div
                                className="image-overlay"
                                onClick={() =>
                                  handleCardClick(clientItem.client_id)
                                }
                              ></div>
                              <div className="item-title">
                                <span className="footer-title">
                                  {clientItem.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        const boardItem = item as AllBoards;
                        return (
                          <div className="item">
                            <div
                              className="item"
                              key={`board_id_container_${boardItem.id}`}
                              style={{
                                backgroundImage: `url(${
                                  boardItem.coverImage
                                    ? boardItem.coverImage
                                    : placeholderImage
                                })`,
                              }}
                            >
                              <ShareIcon
                                className="share-overlay share-icon"
                                onClick={(e) => shareBoard(e, boardItem.id)}
                              />
                              <div
                                className="image-overlay"
                                onClick={() =>
                                  handleCardClick(
                                    boardItem.id,
                                    boardItem.clientID
                                  )
                                }
                              ></div>
                              <div className="item-title">
                                <span className="footer-title">
                                  {boardItem.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CarouselView;
