// encode the email, password and token for mfa confirm

export async function encodedMFAConfirm(
  email: string,
  password: string,
  mfaCode: string
) {
  const decodedEmail = email;

  const decodedPassword = password;

  const decodedMFACode = mfaCode;

  // validate email is not empty and is in email format with regex

  if (
    decodedEmail === undefined ||
    decodedEmail === null ||
    decodedEmail === "" ||
    typeof decodedEmail !== "string" ||
    !decodedEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  ) {
    console.log("Invalid email at encodedMFAConfirm");

    console.log("1");

    return null;
  }

  // validate password

  if (
    decodedPassword === undefined ||
    decodedPassword === null ||
    decodedPassword === "" ||
    typeof decodedPassword !== "string"
  ) {
    console.log("Invalid password at encodedMFAConfirm");

    console.log("12");

    return null;
  }

  // validate mfaCode

  if (
    decodedMFACode === undefined ||
    decodedMFACode === null ||
    decodedMFACode === "" ||
    typeof decodedMFACode !== "string"
  ) {
    console.log("Invalid mfaCode at encodedMFAConfirm");

    console.log("1");

    return null;
  }

  try {
    const encodedEmail = btoa(decodedEmail);
    console.log("Encoded Email", encodedEmail);

    const encodedPassword = btoa(decodedPassword);
    console.log("Encoded Password", encodedPassword);

    const encodedMFACode = btoa(decodedMFACode);
    console.log("Encoded MFA Code", encodedMFACode);

    // combine encoded email and password to create a single string

    const encodedMFAConfirmString = `${encodedEmail}&${encodedPassword}&${encodedMFACode}`;

    console.log("Encoded MFA Confirm", encodedMFAConfirmString);

    return encodedMFAConfirmString;
  } catch (err) {
    console.log(`Failed to encode MFA Confirm at encodedMFAConfirm: ${err}`);

    return null;
  }
}

// encode the email for magic link

export async function encodeEmailMl(email: string) {
  // verify the email

  if (email === undefined || email === null || email === "") {
    return null;
  }

  // try catch block for encoding the email

  try {
    // encode the email

    const encodedEmail = btoa(email);

    return encodedEmail;
  } catch (error) {
    return null;
  }
}
