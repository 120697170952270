import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./error.scss";

const ImagePage = () => {
  const navigate = useNavigate();

  return (
    <div id="error_page">
      <div id="error_card">
        <h1>404</h1>
        <p>Page Not Found</p>
        <button onClick={() => navigate("/dashboard/")}>Go to Dashboard</button>
      </div>
    </div>
  );
};

export default ImagePage;
