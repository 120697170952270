import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getGPTKeywords } from "../../models/images.model";
import { ImageType, AllBoards, GPTSearch, AttributeToggle } from "../../types";
import Photo from "./photo.component";
import "./photosContainer.scss";
import Swal from "sweetalert2";
import { fetchingImages } from "../../utilities/fetchingImages.utils";
import { BoardContext } from "../../context/BoardsContext";
import KoalaImage from "../../assets/images/koala/smart_search_kevin.svg";
import { VariableSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { fetchingSharedImages } from "../../models/shared-board.model";
import { ReactComponent as CheckBoxFilled } from "../../assets/images/icons/check-square-regular.svg";
import { ReactComponent as CheckBox } from "../../assets/images/icons/square-regular.svg";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import { useAttributeContext } from "../../context/UserAttributeContext";
import localforage from "localforage";
import useResponsiveGrid from "../../hooks/gridResizer";

type PhotosContainerProps = {
  selectType:
    | "board"
    | "client"
    | "dashboard"
    | "search"
    | "keyword"
    | "shared";
  boardId?: string;
  clientID?: string;
  tagArray?: string[] | null;
  setShowBulk: (showBulk: boolean) => void;
  showBulk: boolean;
  selectedBulkImageIds: string[];
  setBulkSelectedImageIds: (imageIds: string[]) => void;
  selectedImageIds: string[];
  setSelectedImageIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFilters?: Record<string, Record<string, string[]>> | null;
  searchWord?: string;
  selectedSearchType?: "smart" | "search" | "attributes";
  gptSearch?: string;
  btnSearchClicked?: boolean;
  singleImageID?: string | null;
  setSingleImageID: (image: string | null) => void;
  setShowSingleImageModal: (showSingleImageModal: boolean) => void;
  andOrToggle?: AttributeToggle;
};

export type FilteredBoards = {
  id: string;
  title: string;
  coverImage: string;
  active: boolean;
  clientID: string;
  client: boolean;
};

const PhotosContainer: React.FC<PhotosContainerProps> = ({
  selectType,
  boardId,
  clientID,
  setShowBulk,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
  tagArray,
  selectedFilters,
  searchWord,
  selectedSearchType,
  gptSearch,
  btnSearchClicked,
  singleImageID,
  setSingleImageID,
  setShowSingleImageModal,
  andOrToggle,
}) => {
  // track if font's are loaded (needed for slower networks)
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const [images, setImages] = useState<ImageType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  //This is for smart search
  const [smartSearchImages, setSmartSearchImages] = useState<ImageType[]>([]);
  const [smartSearchAnswer, setSmartSearchAnswer] = useState<GPTSearch>();

  //Workers
  const [filteredImages, setFilteredImages] = useState<ImageType[] | string>(
    []
  );
  const boards = useContext(BoardContext);
  const [allBoards, setAllBoards] = useState<AllBoards[]>([]);

  // Progress Bar
  const [scrollProgress, setScrollProgress] = useState(0);
  const [lastRowIndex, setLastRowIndex] = useState<number>(0);
  const [lastRowReached, setLastRowReached] = useState<Boolean>(false);

  // User-Attributes Context
  const userAttributeContext = useAttributeContext();

  useEffect(() => {
    const font = new FontFace(
      "Comfortaa",
      "url(../src/assets/fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf)"
    );
    font
      .load()
      .then(() => {
        document.fonts.add(font);
        setFontsLoaded(true);
      })
      .catch(() => {
        setFontsLoaded(true); // Fallback in case the font fails to load
      });
  }, []);

  // Call for User Attribute Retrieval
  useEffect(() => {
    // Shared Board does not require User Attributes
    if (selectType === "shared") {
      return;
    }
    if (clientID) {
      const callAttributesRetrieval = async () => {
        await userAttributeContext.getAttributeData(clientID);
      };
      callAttributesRetrieval();
    }
  }, [clientID, userAttributeContext.attributeData]);

  useEffect(() => {
    // if boards.isBoardsLoaded is true, and boards.allBoards exists and length is greater than 0, filter them by client id then setAllBoards
    if (
      boards &&
      boards.isBoardsLoaded &&
      boards.allBoards &&
      boards.allBoards.length > 0
    ) {
      if (clientID) {
        console.log("Client ID:", clientID);
        console.log("All Boards:", boards.allBoards);
        const clientBoards = boards.allBoards.filter(
          (board: FilteredBoards) =>
            board.clientID.toString() === clientID && board.client !== true
        );
        console.log("Client Boards:", clientBoards);
        setAllBoards(clientBoards);
      } else {
        console.log("No client ID");
      }
    }
  }, [boards]);

  useEffect(() => {
    const loadAllImages = async () => {
      setIsLoading(true);
      console.log(selectType);
      if (selectType === "client" && clientID) {
        // If the data is not in the cache, set cacheLoad to true, used to display the longer message
        const dataCheck = await localforage.getItem(
          `ds-${selectType}-${clientID}-current`
        );
        if (dataCheck === null) {
          setInitialLoad(true);
        }

        let jsonData = await fetchingImages("client", clientID);
        setImages(jsonData);
      } else if (selectType === "board" && boardId) {
        // If the data is not in the cache, set cacheLoad to true, used to display the longer message
        const dataCheck = await localforage.getItem(
          `ds-${selectType}-${boardId}-current`
        );
        if (dataCheck === null) {
          setInitialLoad(true);
        }
        let jsonData = await fetchingImages("board", boardId);
        setImages(jsonData);
      } else if (selectType === "dashboard") {
        //TODO: Fetch all images (If in use)
      } else if (selectType === "search") {
        //TODO: Fetch all images (If in use)
      } else if (selectType === "keyword") {
        //Filter images by keyword, we want to make it a string
      } else if (selectType === "shared") {
        //Filter images by keyword, we want to make it a string
        //we need to split the token the images
        if (clientID) {
          let boardID = clientID.split(":")[0];
          let shareBoardToken = clientID.split(":")[1];
          let jsonData = await fetchingSharedImages(shareBoardToken, boardID);
          setImages(jsonData);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch images, Missing ID",
        });
      }
      setIsLoading(false);
      setInitialLoad(false);
    };

    loadAllImages();
  }, []);

  //=======================================================================================================
  //=================================Web worker for filtering images=======================================
  //Option 1: Use a web worker to filter the images all at once
  useEffect(() => {
    if (selectedFilters && Object.keys(selectedFilters).length === 0) {
      return;
    }

    if (selectedSearchType === "attributes") {
      setSmartSearchAnswer(undefined);
      setIsLoading(true);
      const workerAttribute = new Worker(
        new URL("../../services/Filter.webworker.ts", import.meta.url)
      );
      workerAttribute.postMessage({ images, selectedFilters, andOrToggle });
      workerAttribute.onmessage = (e) => {
        setFilteredImages(e.data);
        setIsLoading(false);
      };
      return () => {
        workerAttribute.terminate();
        setFilteredImages([]);
      };
    }
  }, [selectedFilters, andOrToggle]);

  //Web worker for filtering images
  useEffect(() => {
    if (selectedSearchType === "search") {
      if (searchWord === "") {
        return;
      }
      //We need to smartSearchAnswer to be undefined
      setSmartSearchImages([]);
      setSmartSearchAnswer(undefined);
      console.log("Search Word", searchWord);
      if (
        searchWord !== undefined ||
        searchWord !== null ||
        searchWord !== ""
      ) {
        setIsLoading(true);
        const workerAttribute = new Worker(
          new URL("../../services/KeywordsFilter.webworker.ts", import.meta.url)
        );
        workerAttribute.postMessage({ images, searchWord });
        workerAttribute.onmessage = (e) => {
          setFilteredImages(e.data);
          setIsLoading(false);
        };
        console.log("Filtered Images", filteredImages);

        return () => {
          workerAttribute.terminate();
          setFilteredImages([]);
        };
      }
    }
    if (selectedSearchType === "smart") {
      if (gptSearch === "") {
        return;
      }
      if (gptSearch !== undefined || gptSearch !== null) {
        setIsLoading(true);
        const getGPTSearch = async () => {
          if (gptSearch) {
            const gptKeywords = await getGPTKeywords(gptSearch);
            setIsLoading(false);
            if (typeof gptKeywords === "string" || gptKeywords === null) {
              Swal.fire({
                icon: "error",
                title: "Failed to fetch keywords",
              });
              return;
            } else {
              //get the images that match the keywords
              const searchWorker = new Worker(
                new URL(
                  "../../services/GptSearch.webworker.ts",
                  import.meta.url
                )
              );
              console.log(gptKeywords?.tags);
              searchWorker.postMessage({
                images,
                searchWord: gptKeywords?.tags,
              });
              searchWorker.onmessage = (e) => {
                setSmartSearchImages(e.data);
                setSmartSearchAnswer(gptKeywords);
                setIsLoading(false);
              };
            }
          }
        };
        getGPTSearch();
      }
    }
  }, [searchWord, btnSearchClicked, gptSearch]);

  const isAnyCheckboxSelected = selectedImageIds.length > 0;

  const handleImageSelection = useCallback(
    (id: string) => {
      setSelectedImageIds((prevIds: string[]) => {
        const isSelected = prevIds.includes(id);
        const updatedIds = isSelected
          ? prevIds.filter((imageId: string) => imageId !== id)
          : [...prevIds, id];
        const updatedBulkIds = isSelected
          ? selectedBulkImageIds.filter((imageId) => imageId !== id)
          : [...selectedBulkImageIds, id];

        setShowBulk(updatedBulkIds.length > 0);

        setBulkSelectedImageIds(updatedBulkIds);
        return updatedIds;
      });
    },
    [
      selectedBulkImageIds,
      setBulkSelectedImageIds,
      setSelectedImageIds,
      setShowBulk,
    ]
  );

  let allImagesInViewSelected: boolean;

  const handleSelectAllImages = useCallback(() => {
    setSelectedImageIds((prevSelectedImageIds) => {
      let targetImages: ImageType[];

      // Determine which images to select based on the current view
      if (Array.isArray(filteredImages) && filteredImages.length > 0) {
        targetImages = filteredImages;
      } else if (smartSearchImages.length > 0) {
        targetImages = smartSearchImages;
      } else {
        targetImages = images;
      }

      // Get the IDs of the images in view
      const targetImageIds = targetImages.map((image) => image.id);

      // Create a new array that includes the previous IDs and the new ones
      const newSelectedImageIds = Array.from(
        new Set([...prevSelectedImageIds, ...targetImageIds])
      );

      // If all images in view are selected, deselect all images option should be shown
      // allImagesInViewSelected = targetImages.every((image) =>
      //   prevSelectedImageIds.includes(image.id)
      // );

      const prevSelectedImageIdsSet = new Set(prevSelectedImageIds);

      const allImagesInViewSelected = images.every((image) =>
        prevSelectedImageIdsSet.has(image.id)
      );

      if (
        (prevSelectedImageIds.length === targetImages.length ||
          allImagesInViewSelected) &&
        prevSelectedImageIds.length > 0
      ) {
        console.log("Deselecting all images");
        setBulkSelectedImageIds([]);
        setShowBulk(false);
        return [];
      } else {
        console.log("Selecting all images");
        setBulkSelectedImageIds(newSelectedImageIds);
        setShowBulk(true);
        return newSelectedImageIds;
      }
    });
  }, [
    images,
    smartSearchImages,
    filteredImages,
    setBulkSelectedImageIds,
    setShowBulk,
  ]);
  //=======================================================================================================
  //==========================================Display images===============================================
  // Custom hook that returns the column count, row height, and resizing state
  const { isResizing, columnCount, rowHeight } = useResponsiveGrid();

  const PADDING_VERTICAL = 20; // Top and bottom padding for the grid container

  const GUTTER_SIZE = 15; // Space between cells

  const getColumnWidth = (width: number, columnCount: number) => {
    const gutterSpace = GUTTER_SIZE * (columnCount - 1);
    return (width - gutterSpace) / columnCount;
  };

  const getRowHeight = () => {
    return rowHeight; // Maintain static row height, adjust if needed
  };

  // Progress Bar - update final visible row index and check
  // if last row has been reached when scrolling
  const handleItemsRendered = ({
    visibleRowStopIndex,
  }: {
    visibleRowStopIndex: number;
  }) => {
    // visibleRowIndex -> index of final row that can be seen on view of grid on screen
    const rowCount = Math.ceil(images.length / columnCount);
    setLastRowIndex(visibleRowStopIndex);

    if (visibleRowStopIndex === rowCount - 1) {
      setLastRowReached(true);
    } else {
      setLastRowReached(false);
    }
  };

  const renderImages = (displayImages: ImageType[], sourceKey = "source") => {
    // Check if all images in view are selected

    // const allImagesInViewSelected = displayImages.every((image) =>
    //   selectedImageIds.includes(image.id)
    // );

    const selectedImageIdsSet = new Set(selectedImageIds);

    const allImagesInViewSelected = displayImages.every((image) =>
      selectedImageIdsSet.has(image.id)
    );
    return displayImages.length > 0 ? (
      <>
        {selectType !== "shared" && (
          <div className="uk-flex uk-flex-between select_container">
            <button
              className={`uk-button uk-button-primary ${
                selectedImageIds.length === displayImages.length ||
                allImagesInViewSelected
                  ? "deselect_all"
                  : "select_all"
              } uk-flex`}
              onClick={handleSelectAllImages}
            >
              <span className="button_text" style={{ color: "#fff" }}>
                {selectedImageIds.length === displayImages.length ||
                allImagesInViewSelected
                  ? "Deselect all"
                  : "Select all"}
              </span>
              <div className="icon">
                {selectedImageIds.length === displayImages.length ||
                allImagesInViewSelected ? (
                  <CheckBoxFilled />
                ) : (
                  <CheckBox />
                )}
              </div>
            </button>
          </div>
        )}

        <div className="total-display-container">
          {/* Progress Bar */}
          {/* vertical progress bar that will display the in */}
          <div className="progress_outer_container">
            <span className="progress-label">Image Index</span>
            <div className="progress-bar-container">
              <span className="progress-percentage">
                {Math.round(scrollProgress)}%{" "}
              </span>
              <progress
                value={scrollProgress}
                max={100}
                className="progress-bar"
              ></progress>
            </div>
          </div>

          <p className="total-display-text">
            {displayImages.length} / {images.length} images
          </p>
        </div>
        {isResizing ? (
          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-padding">
            <img
              src={Koala}
              alt="Loading..."
              style={{ width: "150px", height: "150px" }}
            />
          </div>
        ) : (
          <div
            // ! Please note that 'grid-scroll' name is used in height determination of window for progress bar
            // ! be careful with name change
            id="grid_scroll"
            style={{
              height: `calc(${
                smartSearchImages.length > 0 ? "50vh" : "95vh - 50px"
              })`,
              paddingLeft: `${15}px`,
              width: "100%",
            }}
          >
            <AutoSizer>
              {({ height, width }) => (
                <Grid
                  className="grid"
                  columnCount={columnCount}
                  rowCount={Math.ceil(displayImages.length / columnCount)}
                  columnWidth={(index) => getColumnWidth(width, columnCount)}
                  rowHeight={getRowHeight}
                  width={width}
                  height={height - 2 * PADDING_VERTICAL}
                  itemData={displayImages}
                  overscanRowCount={3}
                  onItemsRendered={handleItemsRendered}
                >
                  {Cell}
                </Grid>
              )}
            </AutoSizer>
          </div>
        )}
      </>
    ) : (
      <div className="uk-width-1-1 search_result_photo_container ">
        <div id="no_images_container">
          <div className="no_image">
            <div id="no_image_div">No related images found</div>
          </div>
        </div>
      </div>
    );
  };

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
    data: any;
  }) => {
    // Determine height of viewing window
    const div = document.querySelector("#grid_scroll");
    const height = div ? div.clientHeight : "null";

    if (height && height !== "null") {
      // total rows needed for all images
      const rowsNeeded = Math.ceil(data.length / columnCount);
      let fractionSeen = 0;

      // Determine if round up is needed for lower row visibility:
      let ratio = height / rowHeight;
      const floorValue = Math.floor(ratio);
      const ceilingValue = Math.ceil(ratio);
      const differenceToFloor = ratio - floorValue;
      const differenceToCeiling = ceilingValue - ratio;

      // if last row is visible, increase lastRowIndex for final 100% display
      if (lastRowReached) {
        fractionSeen = ((lastRowIndex + 1) / rowsNeeded) * 100;
      }
      // If closer to floor -> Last row Images not visible more than 50%
      else if (differenceToFloor < differenceToCeiling) {
        fractionSeen = (lastRowIndex / rowsNeeded) * 100;
      } else {
        fractionSeen = ((lastRowIndex + 1) / rowsNeeded) * 100;
      }

      setScrollProgress(Math.min(Math.ceil(fractionSeen), 100));
    }

    const image = data[rowIndex * columnCount + columnIndex];

    if (!image) {
      return null; // or return a placeholder component
    }

    // Calculate margins for the first column and first row
    const marginLeft = columnIndex === 0 ? GUTTER_SIZE / 1 : 0;
    const marginTop = rowIndex === 0 ? GUTTER_SIZE / 1 : 0;

    const cellStyle = {
      ...style,
      marginLeft: `${marginLeft}px`,
      marginTop: `${marginTop}px`,
      left: Number(style.left) + (columnIndex > 0 ? GUTTER_SIZE : 0),
      right:
        Number(style.right) + (columnIndex < columnCount - 1 ? GUTTER_SIZE : 0), // Adjust the '3' based on column count - 1
      top: Number(style.top) + (rowIndex > 0 ? GUTTER_SIZE : 0),
      width: Number(style.width) - GUTTER_SIZE,
      height: `10vh`,
      padding: GUTTER_SIZE / 2,
    };

    return (
      <div style={{ ...cellStyle, boxSizing: "border-box" }}>
        <Photo
          id={image.id}
          source={image.source}
          title={image.title}
          description={image.description}
          keywords={image.keywords}
          isAnyCheckboxSelected={isAnyCheckboxSelected}
          handleImageSelection={handleImageSelection}
          selectedImageIds={selectedImageIds}
          ocr={image.ocr}
          allBoards={allBoards}
          clientID={clientID ? clientID : ""}
          selectType={selectType}
          setSingleImageID={setSingleImageID}
          setShowSingleImageModal={setShowSingleImageModal}
        />
      </div>
    );
  };

  return (
    <div id="photos_container">
      {smartSearchImages.length > 0 && Array.isArray(smartSearchImages) && (
        <>
          <div className="uk-width-1 smart-container">
            <div className="images_header">
              <img
                id="search_results_koala_image"
                className=""
                src={KoalaImage}
                alt="MDI Koala"
              />
            </div>
            <div id="search_results_koala_speech" className="">
              <p>
                {smartSearchAnswer?.description
                  ? smartSearchAnswer?.description
                  : "Failed to connect to server"}
                <br /> <br />
                <b>Keywords</b>
                <br />
                {smartSearchAnswer?.tags?.join(", ")}
              </p>
            </div>
          </div>
        </>
      )}
      <div id="photos_grid" className="margin_for_mobile">
        {isLoading ? (
          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            {initialLoad && fontsLoaded && (
              <>
                <span className="uk-text-center cache_title">
                  Thank you for your patience!
                </span>
                <span className="uk-text-center cache_message">
                  We're currently preloading data to ensure a smoother
                  experience once everything is up and running. <br />
                  This might take a few minutes, but it's all part of enhancing
                  your future experience with us. <br />
                  Hang tight!
                </span>
              </>
            )}

            <img
              src={Koala}
              alt="Loading..."
              style={{ width: "150px", height: "150px" }}
            />
          </div>
        ) : smartSearchAnswer !== undefined ? (
          // From search results page, search by keyword
          <>
            {smartSearchImages.length > 0 &&
            Array.isArray(smartSearchImages) ? (
              <>{renderImages(smartSearchImages)}</>
            ) : (
              <div className="uk-width-1-1 search_result_photo_container">
                <div id="no_images_container">
                  <div className="no_image">
                    <div id="no_image_div">No related images found</div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {filteredImages.length > 0 && typeof filteredImages !== "string"
              ? //For filtered images
                renderImages(filteredImages)
              : filteredImages === "No images found" && selectedFilters
              ? //For no images found
                renderImages([])
              : //For all images
                renderImages(images)}
          </>
        )}
        {/* <div ref={loadMoreRef}></div> */}
      </div>
    </div>
  );
};

export default PhotosContainer;
