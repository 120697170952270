import React from "react";
import ReactDOM from "react-dom/client";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.js";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import { UserAttributeContextProvider } from "./context/UserAttributeContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_Development === "production") {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  LogRocket.init("beonesim/sim");
}

root.render(
  <React.StrictMode>
    <UserAttributeContextProvider>
      <App />
    </UserAttributeContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js").then(
      (registration) => {
        console.log(
          "Service Worker registration successful with scope: ",
          registration.scope
        );
      },
      (err) => {
        console.log("Service Worker registration failed: ", err);
      }
    );

    navigator.serviceWorker.register("/cache-service-worker.js").then(
      (registration) => {
        console.log(
          "Image Cache Worker registration successful with scope: ",
          registration.scope
        );
      },
      (err) => {
        console.log("Image Cache Worker registration failed: ", err);
      }
    );
  });
}
