import React from "react";
import { createImage } from "../../models/images.model";
import { ImageUploadType } from "../../types";

import "./uploadImage.scss";

interface UploadImageEditProps {
  image: ImageUploadType | undefined;
  uploadType: string;
  handleImageSubmit: (imageId: string) => void;
  setWindowToDisplay: React.Dispatch<React.SetStateAction<string>>;
}

const UploadImageEdit: React.FC<UploadImageEditProps> = ({
  image,
  uploadType,
  handleImageSubmit,
  setWindowToDisplay,
}) => {
  // console.log("image:", image);

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [keywords, setKeywords] = React.useState("");
  const [ocr, setOcr] = React.useState("");

  const handleImageEditSubmit = async (imageId: string) => {
    // create blob from image source
    const blob = await fetch(image?.source || "").then((r) => r.blob());

    // Add the image to the database
    const reponse: string | { insertID: number } = await createImage({
      clientID: "1",
      originalName: image?.originalname || "",
      title: title,
      description: description,
      keywords: [keywords],
      ocr: ocr,
      type: image?.type || "",
      source: blob,
    });

    if (typeof reponse === "object" && reponse.insertID) {
      if (uploadType === "Single") {
        setWindowToDisplay("upload");
      } else if (uploadType === "Multiple") {
        setWindowToDisplay("multiple");
      }
      handleImageSubmit(imageId);
    } else {
      console.log("image not submitted");
    }
  };

  const handleImageEditCancel = () => {
    if (uploadType === "Single") {
      setWindowToDisplay("upload");
    } else if (uploadType === "Multiple") {
      setWindowToDisplay("multiple");
    }
  };

  const handleImageEditField = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "keywords":
        setKeywords(e.target.value);
        break;
      case "ocr":
        setOcr(e.target.value);
        break;
      default:
        break;
    }
  };

  return image ? (
    <div
      id="upload_image_page_edit_component_container"
      className="uk-flex uk-width-1-1@s"
    >
      <div className="uk-width-1-1">
        <div id="upload_form_container" className="uk-width-1-1@s">
          <div
            id="upload_form_container_main_card"
            className="uk-card uk-card-default uk-padding "
          >
            <div className="uk-grid">
              <div className="uk-width-1-1@s uk-width-2-5@m">
                <img src={image.source} alt="image" />
                <br />
                <br />
              </div>
              <div className="uk-width-1-1@s uk-width-3-5@m right_side">
                <div className="uk-card uk-card-default uk-card-body">
                  {/* name input */}
                  <div className="uk-margin">
                    <label
                      className="uk-form-label"
                      htmlFor="form-stacked-text"
                    >
                      Title
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="form-stacked-text"
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={title}
                        onChange={handleImageEditField}
                      />
                    </div>
                  </div>
                  {/* description input */}
                  <div className="uk-margin">
                    <label
                      className="uk-form-label"
                      htmlFor="form-stacked-text"
                    >
                      Description
                    </label>
                    <div className="uk-form-controls">
                      <textarea
                        className="uk-textarea"
                        rows={5}
                        placeholder="Description"
                        name="description"
                        value={description}
                        onChange={handleImageEditField}
                      ></textarea>
                    </div>
                  </div>
                  {/* keywords input */}
                  <div className="uk-margin">
                    <label
                      className="uk-form-label"
                      htmlFor="form-stacked-text"
                    >
                      Keywords
                    </label>
                    <div className="uk-form-controls">
                      <textarea
                        className="uk-textarea"
                        rows={5}
                        placeholder="Keywords"
                        name="keywords"
                        value={keywords}
                        onChange={handleImageEditField}
                      ></textarea>
                    </div>
                  </div>
                  {/* ocr input */}
                  <div className="uk-margin">
                    <label
                      className="uk-form-label"
                      htmlFor="form-stacked-text"
                    >
                      OCR
                    </label>
                    <div className="uk-form-controls">
                      <textarea
                        className="uk-textarea"
                        rows={5}
                        placeholder="OCR"
                        name="ocr"
                        value={ocr}
                        onChange={handleImageEditField}
                      ></textarea>
                    </div>
                  </div>
                  {/* Submit */}
                  <div className="uk-margin">
                    <button
                      className="btn"
                      onClick={() => {
                        handleImageEditSubmit(image.id);
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn_cancel"
                      onClick={() => {
                        handleImageEditCancel();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>Image is undefined</>
  );
};

export default UploadImageEdit;
