import { fetchFilters, getFiltersChecker } from "../models/filters.model";
import { Attribute, FilterType } from "../types";
import localforage from "localforage";
import Swal from "sweetalert2";

//============================Status Checker============================
async function getUpdateChecker(
  clientID: string,
  boardID: string | null,
  storageString: string,
  selectType: string,
  abortSignal: AbortSignal
) {
  try {
    //Get the last updated time from the server
    const newDataChecker = await getFiltersChecker(clientID, boardID);
    //Get the local data from the storage
    const localData = await localforage.getItem(storageString + "-current");
    if (
      localData &&
      typeof localData === "string" &&
      typeof newDataChecker === "number"
    ) {
      const parsedData = JSON.parse(localData);
      // console.log(
      //   parsedData.updated_at,
      //   newDataChecker,
      //   "here we are for",
      //   storageString
      // );
      console.log(
        `${parsedData.updated_at} ${newDataChecker} here we are for ${storageString}`
      );

      console.log(typeof parsedData.updated_at);
      console.log(typeof newDataChecker);

      //If the data is not the same as the last received data, fetch new data
      if (newDataChecker > parsedData.updated_at) {
        console.log("Fetching new data");
        const newData = await fetchFilters(
          selectType,
          clientID,
          boardID,
          abortSignal
        );

        if (typeof newData !== "string") {
          if (newData.statusCode === 204 || newData.statusCode === 206) {
            return;
          } else {
            console.log(newData.updated_at);
            localforage.setItem(
              storageString + "-new",
              JSON.stringify(newData)
            );
            return;
          }
        } else {
          console.log("Failed to fetch filters");
          return;
        }
      } else {
        console.log("No need to fetch");
      }
    } else {
      console.log("Failed to check latest updated at time");
    }
  } catch (error) {
    console.log("Failed to check latest updated at time", error);
    return;
  }
}

//============================Fetch Cached Filters============================
export async function fetchCachedFilters(
  selectType: string,
  clientID: string,
  boardID: string | null,
  abortSignal: AbortSignal
): Promise<FilterType | string> {
  try {
    let storageString = "";
    if (boardID === null) {
      storageString = `filters-${selectType}-${clientID}`;
    } else {
      storageString = `filters-${selectType}-${clientID}-${boardID}`;
    }

    //We should check if there is a new cache of the filters
    const Toast = Swal.mixin({
      toast: true,
      position: "top-right",
      width: "30$%",
      padding: "1rem",
      confirmButtonColor: "#3085d6",
    });
    //Check if there is a new data set
    const newestDataSet = await localforage.getItem(storageString + "-new");
    if (newestDataSet && typeof newestDataSet === "string") {
      await Toast.fire({
        icon: "success",
        title: "New Filters Loaded",
        padding: "1rem",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Update Now",
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("Updating filters now");
          await localforage.setItem(
            storageString + "-current",
            JSON.stringify(JSON.parse(newestDataSet))
          );
          await localforage.removeItem(storageString + "-new");
        }
      });
    }
    //-------------If there is no new data set, check the local storage for the filters---------------//
    const localFilters: string | null = await localforage.getItem(
      storageString + "-current"
    );
    //If no filters found in local storage, fetch them from the server

    if (!localFilters || localFilters === null) {
      console.log("fetching new filters");
      const newFilters = await fetchFilters(
        selectType,
        clientID,
        boardID,
        abortSignal
      );

      if (typeof newFilters !== "string") {
        //Success response
        if (newFilters.statusCode === 204 || newFilters.statusCode === 206) {
          return newFilters;
        } else {
          localforage.setItem(
            storageString + "-current",
            JSON.stringify(newFilters)
          );
          return newFilters;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch filters",
          text: "Failed to fetch filters",
        });
        return "Failed to fetch filters";
      }
    } else {
      console.log("getCachedFilters", localFilters);
      //If filters are in local storage, parse and return them but also make a call to the server to check the last updated time
      getUpdateChecker(
        clientID,
        boardID,
        storageString,
        selectType,
        abortSignal
      );

      console.log("Returning cached filters");
      const filtersObject = JSON.parse(localFilters);
      console.log("Filters Object", filtersObject);
      return filtersObject;
    }
  } catch (error) {
    console.log("Error fetching cached filters", error);
    // clear the cache
    localforage.removeItem(`filters-${selectType}-${clientID}-new`);
    localforage.removeItem(`filters-${selectType}-${clientID}-current`);

    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
    });
    return "Error fetching cached filters";
  }
}
