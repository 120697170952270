import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import deleteBoard from "../../assets/images/icons/delete.svg";
import deleteHoverBoard from "../../assets/images/icons/deleteHover.svg";
import editBoard from "../../assets/images/icons/edit.svg";
import editHoverBoard from "../../assets/images/icons/editHover.svg";
import expandIcon from "../../assets/images/icons/expand-solid.svg";
import pinBoard from "../../assets/images/icons/pin.svg";
import pinHoverBoard from "../../assets/images/icons/pinHover.svg";
import xMarkIcon from "../../assets/images/icons/x-solid.svg";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import Swal from "sweetalert2";
import "./photo.scss";
import { AllBoards, BoardType, ImageType } from "../../types";
import { FilteredBoards } from "./photosContainer.component";
import {
  addImagesToBoard,
  deleteImage,
  removeImagesFromBoard,
} from "../../models/images.model";
import localforage, { clear } from "localforage";

//set the images variable to the jsonImagesData in typescript

// extends ImageType
interface PhotoProps extends ImageType {
  isAnyCheckboxSelected?: any;
  handleImageSelection: (id: string) => void;
  selectedImageIds: string[];
  allBoards?: FilteredBoards[];
  clientID: string;
  selectType:
    | "board"
    | "client"
    | "dashboard"
    | "search"
    | "keyword"
    | "shared";
  setSingleImageID: (image: string | null) => void;
  setShowSingleImageModal: (showSingleImageModal: boolean) => void;
}

const Photo: React.FC<PhotoProps> = ({
  id,
  source,
  title,
  description,
  keywords,
  isAnyCheckboxSelected,
  handleImageSelection,
  selectedImageIds,
  allBoards,
  clientID,
  selectType,
  setSingleImageID,
  setShowSingleImageModal,
}) => {
  const { boardID } = useParams<{ boardID: string }>();

  // Use states
  const [showTopBar, setShowTopBar] = useState(false);
  const [sideMenuHidden, setSideMenuHidden] = useState(true);

  // Using use effect so that the hover doesn't stay on fast mouse movements.
  const [footerHovered, setFooterHovered] = useState(false);

  // These are the state for all 4 icons
  const [iconPinSource, setIconPinSource] = useState(pinBoard);
  const [iconEditSource, setIconEditSource] = useState(editBoard);
  const [iconDeleteSource, setIconDeleteSource] = useState(deleteBoard);

  // Checking if image is hovered
  const [isHovered, setIsHovered] = useState(false);

  const isSelected = useMemo(
    () => selectedImageIds.includes(id),
    [id, selectedImageIds]
  );

  const handleImageHover = (id: string) => {
    if (isSelected) return;
    // console.log("Hovering on image: " + id);
  };

  const navigate = useNavigate();
  let keywordsString = keywords.slice(0, 5).join(", ");

  // The i button of image - handler

  const infoButtonHandler = (boolHide: boolean) => {
    // if photo is selected, do not show the side menu
    if (!isSelected) {
      setSideMenuHidden(!boolHide);
    }
  };

  // The footer of image - handler
  const footerHandler = (boolShowFooter: boolean) => {
    // if photo is selected, do not show the footer
    if (!isSelected) {
      setFooterHovered(boolShowFooter);
    }
  };

  const viewSingleImage = async (image_id: string, e: React.MouseEvent) => {
    e.preventDefault();
    if (e.button === 2) {
      //Right click
    } else {
      setSingleImageID(image_id);
      setShowSingleImageModal(true);
    }
  };

  const imageRightClickHandler = (image_id: string, e: React.MouseEvent) => {
    // redirect to image page in a new window on right click, requires e.preventDefault() on app level to prevent context menu
    e.preventDefault();
    const originalWindow = window;
    const newWindow = window.open(`/image/${image_id}`, "_blank");

    // Blur the new window and focus back on the original window
    if (newWindow) {
      newWindow.blur();
      originalWindow.focus();
    }
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      setShowTopBar(false);
      setFooterHovered(false);
    };

    const footerElement = document.getElementById(`image_${id}`);
    footerElement?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      footerElement?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [id]);

  // The following segment code will be used to handle the 4 icons on the sidemenu of the image.
  // Here we change the icon source depending on the icon type and if it is hovered or not
  const handleHover = (iconType: string, isHovered: boolean) => {
    switch (iconType) {
      case "pin":
        setIconPinSource(isHovered ? pinHoverBoard : pinBoard);
        break;
      case "edit":
        setIconEditSource(isHovered ? editHoverBoard : editBoard);
        break;
      case "delete":
        setIconDeleteSource(isHovered ? deleteHoverBoard : deleteBoard);
        break;
      case "singleView":
        setIconEditSource(isHovered ? editHoverBoard : editBoard);
        break;
      default:
        break;
    }
  };

  const [pinStateHidden, setPinState] = useState(true);
  const [editStateHidden, setEditState] = useState(true);
  const [deleteStateHidden, setDeleteState] = useState(true);

  const handleHideIcons = () => {
    setPinState(true);
    setEditState(true);
    setDeleteState(true);
  };

  const handleIconClick = (
    iconType: string,
    isClicked: boolean,
    image_id: string
  ) => {
    //On click hide all icons, keep the previous state of the icon that was clicked
    //Use the previous state of the icon to determine if it should be shown or not
    const pinCurrentState = pinStateHidden;
    const deleteCurrentState = deleteStateHidden;
    handleHideIcons();
    switch (iconType) {
      case "pin":
        if (pinCurrentState === true) {
          setPinState(false);
        } else {
          setPinState(true);
        }
        break;
      case "edit":
        navigate(`/image/${image_id}`);
        break;
      case "delete":
        if (deleteCurrentState === true) {
          setDeleteState(false);
          removeDeleteImage(id);
        } else {
          setDeleteState(true);
        }
        break;
      default:
        break;
    }
  };
  /*-------------------------------------*/
  /*-----------Board functions-----------*/
  /*-------------------------------------*/

  const folderHandler = (image_id: string, boolAddBoard: Boolean) => {
    if (boolAddBoard) {
      handleMessage("Added to new board");
      setSideMenuHidden(true);
    }
  };

  const getBoardsForImageRemoval = async () => {
    try {
      console.log("CALLING BOARD IMAGE REMOVAL");
      const allBoardsData = await localforage.getItem("allBoards");
      console.log("---ALL ----", allBoardsData);
      if (allBoardsData && typeof allBoardsData === "string") {
        const boards = JSON.parse(allBoardsData).boards;
        if (boards) {
          const matchingBoards = boards.filter(
            (board: any) => board.clientID === clientID
          );
          return matchingBoards;
        }
        return null;
      }
    } catch (error) {
      console.error("Error in getBoardsData:", error);
    }
  };

  const [loading, setLoading] = useState(false);

  const pinHandler = async (
    image_id: string,
    board_id: string,
    board_name: string
  ) => {
    // setSideMenuHidden(true);
    setLoading(true);
    const addImageResponse = await addImagesToBoard({
      clientID: clientID,
      boardID: board_id,
      imagesToAdd: [image_id],
    });

    if (addImageResponse.rStatus === "error") {
      Swal.fire({
        icon: "error",
        title: "Failed to pin image",
        text: addImageResponse.rMessage,
      });
    } else if (addImageResponse.rStatus === "exists") {
      Swal.fire({
        icon: "info",
        title: "Image already pinned",
        text: `Image is already pinned to ${board_name}`,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Image pinned",
        text: `Image pinned to ${board_name}`,
      });
      try {
        localforage.removeItem(`ds-board-${board_id}-current`);
        localforage.removeItem(`filters-board-${clientID}-${board_id}-current`);
      } catch (error) {
        console.log("Error removing localforage item", error);
      }
    }
    console.log(addImageResponse);

    setSideMenuHidden(true);
    setLoading(false);
  };

  // ================== IMAGE DELETION (CLIENT) OR REMOVAL (BOARD) ==================

  // First Image Removal / Deletion Popup Window
  const removeDeleteImage = (imageID: string) => {
    if (selectType === "client" || selectType === "board") {
      Swal.fire({
        title: "Image " + (selectType === "client" ? "Deletion" : "Removal"),
        text: (selectType === "client" ? "Delete" : "Remove") + " this image?",
        icon: "warning",
        iconColor: "#BB2D3B",
        showCancelButton: true,
        confirmButtonColor: "#BB2D3B",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          "Yes, " + (selectType === "client" ? "delete" : "remove") + " it!",
      }).then((result) => {
        if (result.isConfirmed) {
          imagePopupConfirmation(imageID);
        }
      });
    } else {
      console.error(
        "Image Deletion / Removal requested for type that is not client or board"
      );
    }
  };

  // Image Deletion / Removal Confirmation
  const imagePopupConfirmation = (imageID: string) => {
    // Type = 'client' -> Image Deletion; type = 'board' -> remove
    // ADDITONAL CHECK for future changes, type must be "client" or "board" for image removal
    if (selectType === "client" || selectType === "board") {
      Swal.fire({
        title:
          selectType === "client"
            ? "Deletion Confirmation"
            : "Removal Confirmation",
        text: `Please type ${
          selectType === "client" ? "DELETE" : "REMOVE"
        } to confirm`,
        input: "text",
        inputAttributes: {
          autocomplete: "off",
        },
        confirmButtonText:
          "Confirm " + (selectType === "client" ? "Deletion" : "Removal"),
        confirmButtonColor: "#BB2D3B",
        showCloseButton: true,
        cancelButtonText: "Cancel",
        showCancelButton: true,
        icon: "warning",
        iconColor: "#BB2D3B",
        showConfirmButton: true,
        inputValidator(value) {
          if (!value) {
            return (
              "Please type " +
              (selectType === "client" ? "DELETE" : "REMOVE") +
              " to confirm"
            );
          }
          if (value !== (selectType === "client" ? "DELETE" : "REMOVE")) {
            return (
              "Please type " +
              (selectType === "client" ? "DELETE" : "REMOVE") +
              " to confirm"
            );
          }
        },
      }).then((result): void => {
        if (result.isConfirmed) {
          Swal.fire({
            title: selectType === "client" ? "Deleting..." : "Removing...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          deleteRemoveHandler(id, true);
        } else {
          deleteRemoveHandler(id, false);
        }
      });
    } else {
      console.error(
        "Type for Image Deletion/Removal is neither 'client' nor 'board'"
      );
    }
  };

  // ================== HANDLE DELETE OR REMOVE ==================
  const deleteRemoveHandler = async (
    image_id: string,
    boolDeleteRemoveImage: Boolean
  ) => {
    // Deletion / Removal Cancelled
    if (!boolDeleteRemoveImage) {
      setSideMenuHidden(true);
      setDeleteState(true);
    } else {
      deleteRemoveImageFromBoard(image_id);
    }
  };

  // Determine Image Deletion or Removal based on type as 'client' or 'board' respectively
  const deleteRemoveImageFromBoard = async (image_id: string) => {
    if (selectType === "board") {
      // Image Removal Requested - board page
      await removeImageFromBoard(image_id);
    } else if (selectType == "client") {
      // Image Deletion Requested - client page
      await deleteImageHandle(image_id);
    }
    setDeleteState(true);
    setSideMenuHidden(true);
    setLoading(false);
    window.location.reload();
    navigate(`/dashboard/`);
  };

  const removeImageFromBoard = async (image_id: string) => {
    if (boardID) {
      setLoading(true);

      const removeImageResponse = await removeImagesFromBoard({
        boardID,
        imagesToRemove: [image_id],
      });

      if (removeImageResponse.rStatus === "error") {
        handleMessage("Failed to remove image");
        Swal.fire({
          icon: "error",
          title: "Failed to Remove image",
          confirmButtonColor: "#3085d6",
        });
      } else if (removeImageResponse.rStatus === "success") {
        handleMessage("Image removed from board");
        Swal.fire({
          icon: "success",
          title: "Image Removed",
          showConfirmButton: false,
          confirmButtonColor: "#3085d6",
        });

        try {
          localforage.removeItem(`ds-board-${boardID}-current`);
          localforage.removeItem(
            `filters-board-${clientID}-${boardID}-current`
          );
        } catch (error) {
          console.log("Error removing localforage item", error);
        }
        window.location.reload();
      } else {
        handleMessage("Image not found in board");
      }
    } else {
      handleMessage("Board ID not found, please refresh!");
    }
  };

  // Perform Image Deletion from Client (and removes images from board local forage)
  // NOTE: Image removal from boards handled on back-end
  const deleteImageHandle = async (image_id: string) => {
    if (image_id) {
      let response = await deleteImage(clientID, image_id);
      if (response === "success") {
        Swal.fire({
          icon: "success",
          title: "Image Deleted",
          showConfirmButton: false,
          confirmButtonColor: "#3085d6",
        });
        // Clear thumbnails
        try {
          await localforage.removeItem(`ds-client-${clientID}-current`);
          await localforage.removeItem(`ds-client-${clientID}-new`);

          // check for boards with matching client id to remove from local forage
          const boardIDs = await getBoardsForImageRemoval();
          if (boardIDs) {
            const removalPromises = boardIDs.map((board: any) =>
              Promise.all([
                localforage.removeItem(`ds-board-${board.id}-current`),
                localforage.removeItem(`ds-board-${board.id}-new`),
              ]).catch((error) =>
                console.error("Error removing board items:", error)
              )
            );

            await Promise.all(removalPromises);
          }
        } catch (error) {
          console.error(
            `Error during cache cleanup for client ${clientID} or boards during image deletion:`,
            error
          );
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to delete image",
          confirmButtonColor: "#3085d6",
        });
      }
    } else {
      console.error("Missing client id for Image Deletion");
    }
  };

  // full info card on click handler
  const clickFullCard = (image_id: string) => {
    // Set hidebar true if the card is clicked
    if (showTopBar === true) {
      // If the card is clicked again, hide the top bar
      setShowTopBar(false);
    } else {
      // If the card is not clicked it needs too display
      setShowTopBar(true);
    }
  };

  // This will handle the closing of the full info card
  const xMark = () => {
    setFooterHovered(false);
    setShowTopBar(false);
  };

  const [actionMessage, setActionMessage] = useState("");

  const handleMessage = (message: string) => {
    setActionMessage(message);
    setTimeout(() => {
      setActionMessage("");
    }, 1000);
  };
  //handle show footer only when the user has been on hover for move then 3 seconds on the footer
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const [test, setTest] = useState(0);

  const handleShowFooter = (boolShowFooter: boolean) => {
    if (!isSelected) {
      // Clear any existing timeout
      if (boolShowFooter === true) {
        timeoutId.current = setTimeout(() => {
          setFooterHovered(boolShowFooter);
        }, 500);
      } else {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
      }
    }
  };

  const handleImageClick = (image_id: string, mouseClick: React.MouseEvent) => {
    if (selectType === "shared") {
      viewSingleImage(image_id, mouseClick);
    } else {
      handleImageSelection(id);
    }
  };

  return (
    <div
      id={`image_${id}`}
      className={`uk-card uk-card-default uk-card-small uk-margin-medium-bottom ${
        isAnyCheckboxSelected ? "any-checkbox-selected" : ""
      } ${footerHovered ? "card_footer_hovered" : ""} card_side_menu_${
        sideMenuHidden ? "hidden" : "shown"
      } ${
        selectedImageIds.includes(id) ? "selected" : ""
      } card_image_container`}
      onMouseEnter={() => !isAnyCheckboxSelected && handleImageHover(id)}
      // onMouseDown={(e) => (e.button === 2 ? imageRightClickHandler(id, e) : "")}
      style={{
        backgroundImage: `url(${source})`,
      }}
    >
      {selectType === "shared" ? (
        <></>
      ) : (
        <div
          className={`card_checkbox_container ${
            isAnyCheckboxSelected ? "visible" : ""
          } ${selectedImageIds.includes(id) ? "selected" : ""} ${
            isHovered ? "visible" : ""
          }`}
        >
          <input
            type="checkbox"
            className="card_checkbox"
            checked={selectedImageIds.includes(id)}
            onChange={() => handleImageSelection(id)}
          />
        </div>
      )}
      <div
        className="image_clickable"
        onClick={(e) => handleImageClick(id, e)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      ></div>
      <div
        className="uk-card-footer"
        onMouseEnter={() => handleShowFooter(true)}
        onMouseLeave={() => handleShowFooter(false)}
      >
        <span className="footer_title">{title}</span>
        <span className="footer_description">{keywordsString}</span>
      </div>
      {/* Displaying the i button of the image grid */}
      {selectType !== "shared" && (
        <div
          className="info_button_container"
          onMouseEnter={() => infoButtonHandler(true)}
          onMouseLeave={() => {
            infoButtonHandler(false);
            handleHideIcons();
          }}
        >
          {/* Displaying the sidemenu when clicking on i */}
          <div className={`card_side_menu`}>
            {/* Displaying the 4 icons as images on the sidemenu */}
            {/* Pin */}
            <div className="card_side_menu_item topGap noHightlight">
              <img
                className="imageSizing imageGaps"
                onMouseEnter={() => handleHover("pin", true)}
                onMouseLeave={() => handleHover("pin", false)}
                onMouseDown={() => handleIconClick("pin", true, id)}
                src={iconPinSource}
                alt="Pin"
                key={`pin_${id}`}
                uk-tooltip="title: Pin image to board; pos: right"
              />
              {!pinStateHidden ? (
                <div
                  id="custom_context_menu_pin"
                  className={`custom_context_menu`}
                  onMouseLeave={() => handleIconClick("pin", false, id)}
                >
                  {loading ? (
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                      <img
                        src={Koala}
                        alt="Loading..."
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                  ) : (
                    <>
                      {allBoards && allBoards.length > 0 ? (
                        allBoards.map((board) => {
                          return (
                            <div
                              className="custom_context_menu_item custom_context_menu_item_button"
                              onClick={() =>
                                pinHandler(id, board.id, board.title)
                              }
                            >
                              <span
                                uk-tooltip={`title: ${board.title} ; pos: left`}
                              >
                                {board.title}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <div className="no_resource">No Boards</div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* Image Deletion for Single Image */}
            {selectType === "client" && (
              <div className="card_side_menu_item">
                <img
                  className="imageSizing imageGaps"
                  onMouseEnter={() => handleHover("delete", true)}
                  onMouseLeave={() => handleHover("delete", false)}
                  onMouseDown={() => handleIconClick("delete", true, id)}
                  src={iconDeleteSource}
                  alt="Delete"
                  uk-tooltip="title: Delete Image; pos: right"
                />
              </div>
            )}
            {/* Image Removal from Boards */}
            {selectType === "board" && (
              <div className="card_side_menu_item">
                <img
                  className="imageSizing imageGaps"
                  onMouseEnter={() => handleHover("delete", true)}
                  onMouseLeave={() => handleHover("delete", false)}
                  onMouseDown={() => handleIconClick("delete", true, id)}
                  src={iconDeleteSource}
                  alt="Delete"
                  uk-tooltip="title: Remove image from board; pos: right"
                />
              </div>
            )}
            {/* Single view for images */}
            <div className="card_side_menu_item ">
              <img
                className="imageSizing imageGaps flip"
                onMouseEnter={() => handleHover("singleView", true)}
                onMouseLeave={() => handleHover("singleView", false)}
                onClick={(e) => viewSingleImage(id, e)}
                src={iconEditSource}
                alt="View Image"
                uk-tooltip="title: View image; pos: right"
              />
            </div>
          </div>
          <span className="info_button">i</span>
        </div>
      )}
      {/* This is where the menu is showing up after hovering over the footer menu */}
      <div
        className="card_full_info"
        onMouseLeave={() => footerHandler(false)}
        onMouseDown={() => clickFullCard(id)}
      >
        {/* This is where the x and and the expand is made */}
        {showTopBar ? (
          <div className="backgroundX">
            {/* For the X mark icon */}
            <div className="iconsNavMenu" onMouseDown={() => xMark()}>
              <img
                className="iconSizesNav invert"
                src={xMarkIcon}
                alt="Close"
              />
            </div>
            {/* For the expand icon */}
            <div
              className="iconsNavMenu"
              onMouseDown={(e) => viewSingleImage(id, e)}
            >
              <img className="iconSizesNav" src={expandIcon} alt="Expand" />
              {/* </div> */}
            </div>
          </div>
        ) : null}
        {/* This is where the card data starts.*/}
        <div className="card_full_info_inner">
          {/* This is where the keywords are displayed. */}
          <span className="card_full_info_inner_keywords_title">Keywords:</span>
          <span className="card_full_info_inner_keywords">
            {keywordsString}
          </span>
          {/* The data is displayed here on the full info card. */}
          <span className="card_full_info_inner_title">{title}</span>
          {/* Description Field Removed */}
          {/* <span className="card_full_info_inner_description">
            {description}
          </span> */}
          {/* This is where the rest of the data will be on mouse click. */}
          {showTopBar ? (
            <>
              <img
                src={`${source}`}
                alt={title}
                onMouseDown={(e) => viewSingleImage(id, e)}
              />
              {/* <span className="card_full_info_inner_description">
                test data lorum epsem all other data . random . com . za . hi
                there
              </span> */}
            </>
          ) : null}
        </div>
      </div>
      {
        /* Display Image Action Message  */
        actionMessage !== "" ? (
          <div id="image_action_status_message">{actionMessage}</div>
        ) : null
      }
    </div>
  );
};

export default Photo;
