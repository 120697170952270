import localforage from "localforage";
import { getAllBoards } from "../models/board.model";

async function fetchAndStoreBoards() {
  const getBoards = await getAllBoards();
  if (typeof getBoards !== "string" && Array.isArray(getBoards.boards)) {
    localforage.setItem("allBoards", JSON.stringify(getBoards));
    return getBoards;
  } else {
    return "Failed to fetch boards";
  }
}

export async function loadAllBoards(getNewData: boolean) {
  const fetchBoards = async () => {
    let newBoards = await fetchAndStoreBoards();
    if (typeof newBoards !== "string" && Array.isArray(newBoards.boards)) {
      return newBoards;
    } else {
      return "Failed to fetch boards";
    }
  };

  // Fetches new board data if getNewData is true
  if (getNewData === true) {
    return fetchBoards();
  } else {
    // Check if the data is in storage, if not fetch it
    let getLocalBoards: string | null = await localforage.getItem("allBoards");

    if (typeof getLocalBoards === "string" && getLocalBoards !== null) {
      let localBoards = JSON.parse(getLocalBoards);
      let expiryTime = localBoards.expires_at;

      if (expiryTime < new Date().getTime()) {
        console.log("Data is expired and we fetched it again");
        return fetchBoards();
      } else {
        console.log("Data is not expired");
        return localBoards;
      }
    } else {
      return fetchBoards();
    }
  }
}
