import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UploadImage from "../../components/UploadImage/uploadImage";
import UploadImageEdit from "../../components/UploadImage/uploadImageEdit";
import UploadImageMultipleView from "../../components/UploadImage/uploadImageMultipleView";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";
import { createImage } from "../../models/images.model";
import { createAttribute } from "../../models/attributes.model";
import { ImageUploadType } from "../../types";
import SidePanelModal from "../../components/SideModal/sideModalPanel.component";
import "./upload.scss";
import Swal from "sweetalert2";
import { ManageAttributes } from "../../types";
import { fetchAttributes } from "../../models/wsc.model";
import { useParams } from "react-router-dom";
import WSCImports from "../../components/UploadImage/wscImports/wscImports";
import ManageWSCAttributes from "../../components/WSC/manageWSCAttributes.component";
import { ReactComponent as AddAttribute } from "../../assets/images/icons/square_plus.svg";
import { useAttributeContext } from "../../context/UserAttributeContext";
import localforage from "localforage";
export type UploadPageProps = {
  onThemeChange: () => void;
};

const UploadPage: React.FC<UploadPageProps> = ({ onThemeChange }) => {
  const [boardId, setBoardId] = useState<string | null>(null);
  const [images, setImages] = React.useState<ImageUploadType[]>([]);
  const [uploadType, setUploadType] = React.useState("");

  const { clientID } = useParams<{ clientID: string }>();

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };
  const [showWSCModal, setShowWSCModal] = useState(false);

  const [windowToDisplay, setWindowToDisplay] = React.useState("upload"); // ["upload", "edit", "multiple", "submitting"]
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = React.useState<number | null>(null); //number or null
  const [submittedImages, setSubmittedImages] = React.useState<string[]>([]);
  const [allAttributes, setAllAttributes] = useState<ManageAttributes[]>([]);
  const [attributesLoaded, setAttributesLoaded] = useState(false);

  const userAttributeContext = useAttributeContext();

  const handleImageSubmit = async (imageId: string) => {
    console.log("image submitted"); // update the submitted images array
    setSubmittedImages((prev) => [...prev, imageId]);
    // remove the image from the images array
    setImages((prev) => prev.filter((image) => image.id !== imageId));
  };

  const backgroundImageStyle = `url(/assets/images/koala-attributes2.svg)`;

  useEffect(() => {
    if (submittedImages.length > 0) {
      // has to be greater than 0 because should not run until image is submitted
      // console.log("submittedImages:", submittedImages);
      // console.log("submittedImages.length: ", submittedImages.length);
      // console.log("images.length: ", images.length + 1);
      if (images.length === 0) {
        setWindowToDisplay("submitting");
        // console.log("all images submitted");
        setWindowToDisplay("edit");
      }
    }
  }, [submittedImages]);

  // update heading
  //const [heading, setHeading] = useState("Upload Image");
  const [heading, setHeading] = useState("Manage Attributes");

  useEffect(() => {
    switch (windowToDisplay) {
      // case "upload":
      //   setHeading("Upload Image");
      //   break;
      case "edit":
        setHeading("Edit Image");
        break;
      case "multiple":
        setHeading("Multiple Uploaded Images");
        break;
      case "submitting":
        setHeading("Submitting...");
        break;
      case "wscEdit":
        setHeading("Manage Attributes");
        break;
      // default:
      //   setHeading("Upload Image");
      //   break;
    }
  }, [windowToDisplay]);

  useEffect(() => {
    // Update User Attributes on page render and/or if there is a change to client ID
    if (clientID) {
      // Update User Attributes
      userAttributeContext.updateAttributeData(clientID);
      // UserAttributesContext already fetches all attributes, retrieve
      setAllAttributes(userAttributeContext.allAttributes);
    }
  }, [userAttributeContext.clientID]);

  //We want to open the modal wsc attribute modal
  const openWscAttributeModal = () => {
    console.log("openWscAttributeModal");
    setShowWSCModal(true);
  };

  const closeWscAttributeModal = () => {
    console.log("closeWscAttributeModal");
    setShowWSCModal(false);
  };

  const requestUserAttributeCreation = async (
    clientID: string,
    newAttributeName: string
  ) => {
    const attributeCreationResults = await createAttribute(
      clientID,
      newAttributeName
    );
    if (typeof attributeCreationResults === "string") {
      Swal.fire({
        title: "Failed to Create New User Attribute",
        text: attributeCreationResults,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      Swal.fire({
        title: "Attribute Created",
        icon: "success",
        showCloseButton: true,
      });
      // Update User Attributes
      userAttributeContext.updateAttributeData(clientID);
      setAllAttributes(userAttributeContext.allAttributes);

      // Reload Window
      window.location.reload();
      // setWindowToDisplay("edit");
    }
  };

  // Create New User Attribute
  const createUserAttribute = () => {
    Swal.fire({
      title: "Create Attribute",
      html: `
      <div style="display: flex; flex-wrap: wrap; align-items:center;">
        <label for="swal-input1" style="margin:10 !important">Attribute Name</label>
        <input id="swal-input1" class="swal2-input" maxlength="16" style="margin:0 !important">
      </div>
      `,
      confirmButtonText: "Save",
      showCloseButton: true,
      // customClass: {
      //   confirmButton: "order-1 left",
      // },
      preConfirm: () => {
        const specialChars = ["(", ")", "&", "@", "$", "|", "%", "~"];

        const inputElement = document.getElementById(
          "swal-input1"
        ) as HTMLInputElement;
        let newAttributeName = inputElement.value.trim();
        if (!newAttributeName) {
          Swal.showValidationMessage("Please enter an Attribute Name");
          return;
        } else if (newAttributeName === "") {
          Swal.showValidationMessage("Attribute Name cannot be blank");
          return;
        } else if (newAttributeName.includes(" ")) {
          Swal.showValidationMessage(
            "Attribute names cannot contain spaces; any spaces have been replaced with underscores"
          );
          newAttributeName = newAttributeName.replace(/\s+/g, "_");
          inputElement.value = newAttributeName; // Update the input element's value
          return;
        } else if (newAttributeName.length < 3) {
          Swal.showValidationMessage(
            "Attribute Name cannot be shorter than 3 characters"
          );
          return;
        } else if (newAttributeName.length > 16) {
          Swal.showValidationMessage(
            "Attribute Name cannot be longer than 16 characters"
          );
          return;
        } else {
          if (allAttributes) {
            if (
              allAttributes.find(
                (attribute) => attribute.name === newAttributeName
              )
            ) {
              Swal.showValidationMessage(
                "Duplicate Attribute Name has been given"
              );
              return;
            }
          }
          specialChars.map((char) => {
            if (newAttributeName.includes(char)) {
              Swal.showValidationMessage(
                "Attribute Name cannot contain special characters"
              );
            }
          });
        }
        return { newAttributeName };
      },
    }).then((result): void => {
      if (result.isConfirmed && result.value.newAttributeName && clientID) {
        Swal.fire({
          title: "Creating New Attribute",
          text: "Please wait while the attribute is being created",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        requestUserAttributeCreation(clientID, result.value.newAttributeName);
      }
    });
  };

  return (
    <div id="uploadPage" className="uk-grid">
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          parsedClientID={clientID}
          selectType="none"
        />
      </div>
      {/* <div className="uk-width-1-3@s uk-width-1-5@m">
        {/* <Sidebar /> 
        </div> */}
      {/* This calls the photos container element from the components folder */}
      <div className="uk-width-1-1@s  upload_page">
        <div className="upload_container">
          <div className="uk-flex uk-grid uk-child-width-1-1">
            <div className="uk-flex uk-grid header_icon_container">
              <h2 className=" uk-margin-top uk-margin-bottom title">
                {heading}
              </h2>
              <div className="image_add_div uk-child-width-1-2">
                {/* Image */}
                {heading === "Manage Attributes" &&
                  windowToDisplay === "wscEdit" && (
                    <button
                      className="logo_button"
                      style={{
                        backgroundImage: backgroundImageStyle,
                      }}
                    ></button>
                  )}
                {/* Add Attribute Button */}
                {heading === "Manage Attributes" &&
                  windowToDisplay === "wscEdit" &&
                  attributesLoaded && (
                    <AddAttribute
                      className="AddAttributeButton"
                      onClick={() => createUserAttribute()}
                      data-toggle="tool-tip"
                      title="Create Attribute"
                    />
                  )}
              </div>
            </div>
            {windowToDisplay === "upload" ? (
              <div>
                {/* TODO: */}
                {/* <UploadImage
                  setImages={setImages}
                  setUploadType={setUploadType}
                  setWindowToDisplay={setWindowToDisplay}
                  setCurrentImage={setCurrentImage}
                /> */}
                {/* WSC Imports */}
                <WSCImports
                  openWscAttributeModal={openWscAttributeModal}
                  setWindowToDisplay={setWindowToDisplay}
                />
              </div>
            ) : windowToDisplay === "edit" &&
              currentImage !== null &&
              images.find((image) => image.id === currentImage.toString()) ? (
              <UploadImageEdit
                image={images.find(
                  (image) => image.id === currentImage.toString()
                )}
                uploadType={uploadType}
                handleImageSubmit={handleImageSubmit}
                setWindowToDisplay={setWindowToDisplay}
              />
            ) : windowToDisplay === "multiple" ? (
              <UploadImageMultipleView
                images={images}
                setWindowToDisplay={setWindowToDisplay}
                setCurrentImage={setCurrentImage}
              />
            ) : windowToDisplay === "submitting" ? (
              <div>
                <h1 className="uk-margin-top uk-margin-bottom">
                  Submitting...
                </h1>
              </div>
            ) : windowToDisplay === "wscEdit" ? (
              <ManageWSCAttributes
                client_Id={clientID!}
                setWindowToDisplay={setWindowToDisplay}
                allAttributes={allAttributes}
                setAllAttributes={setAllAttributes}
                attributesLoaded={attributesLoaded}
                setAttributesLoaded={setAttributesLoaded}
              />
            ) : (
              <p>There was an error</p>
            )}
          </div>
        </div>
      </div>
      {/* {showWSCModal && (
        <SidePanelModal
          location="right"
          contentType="wscImport"
          onClose={closeWscAttributeModal}
        />
      )} */}
    </div>
  );
};

export default UploadPage;
