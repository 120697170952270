import { useEffect, useState } from "react";

interface GridSettings {
  columnCount: number;
  rowHeight: number;
  isResizing: boolean;
}

const useResponsiveGrid = (): GridSettings => {
  const [columnCount, setColumnCount] = useState(2);
  const [rowHeight, setRowHeight] = useState(250);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout;

    const handleResize = () => {
      // When the window starts resizing, set isResizing to true
      setIsResizing(true);

      // Clear the previous timeout
      clearTimeout(resizeTimeout);

      // Set a timeout to set isResizing to false after 500ms
      // This means that if the user stops resizing the window for 500ms,
      // isResizing will be set to false and the grid will be displayed with its new size
      resizeTimeout = setTimeout(() => {
        setIsResizing(false);

        // Update column width and row height based on window size
        const width = window.innerWidth;
        console.log("Width", width);

        if (width >= 1920) {
          console.log("In here", width);
          setColumnCount(4);
          setRowHeight(300);
        } else if (width > 1800) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(270);
        } else if (width > 1700) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(260);
        } else if (width > 1600) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(230);
        } else if (width > 1500) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(220);
        } else if (width > 1400) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(210);
        } else if (width > 1300) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(200);
        } else if (width > 1200) {
          console.log("In here", width);

          setColumnCount(4);
          setRowHeight(180);
        } else if (width > 1100) {
          console.log("In here", width);

          setColumnCount(3);
          setRowHeight(220);
        } else if (width > 1050) {
          console.log("In here", width);

          setColumnCount(3);
          setRowHeight(210);
        } else if (width > 1000) {
          console.log("In here", width);

          setColumnCount(3);
          setRowHeight(190);
        } else if (width > 960) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(300);
        } else if (width > 950) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(240);
        } else if (width > 900) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(220);
        } else if (width > 800) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(200);
        } else if (width > 750) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(180);
        } else if (width > 700) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(170);
        } else if (width > 750) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(160);
        } else if (width > 650) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(150);
        } else if (width > 640) {
          console.log("In here", width);

          // Set the column count and row height for widths between 650 and 640
          setColumnCount(2);
          setRowHeight(150);
        } else if (width > 600) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(210);
        } else if (width > 550) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(200);
        } else if (width > 500) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(180);
        } else if (width <= 450) {
          console.log("In here", width);

          setColumnCount(2);
          setRowHeight(140);
        }
      }, 500);
    };

    handleResize();
    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isResizing, columnCount, rowHeight };
};

export default useResponsiveGrid;
