import React from "react";
import "./profileContainer.scss";
import ProfileForm from "./profileForm.component";

const ProfileContainer: React.FC = () => {
  return (
    <div id="profile_container">
      <div
        id="profile_container_card"
        className="uk-card uk-card-default uk-padding"
      >
        <h1>Profile Settings</h1>
        <ProfileForm />
      </div>
    </div>
  );
};

export default ProfileContainer;
