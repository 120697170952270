import React, { useEffect, useState } from "react";
import PhotosContainer from "../../components/Photos/photosContainer.component";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";
// import "./viewSingleClient.scss";
import { AttributeToggle } from "../../types";
import { useParams } from "react-router-dom";
import SidePanelModal from "../../components/SideModal/sideModalPanel.component";

export type ViewSingleClientProps = {
  onThemeChange: () => void;
};

const ViewSingleClientPage: React.FC<ViewSingleClientProps> = ({
  onThemeChange,
}) => {
  const [clientId, setClientId] = useState<string | null>(null);
  const [showBulk, setShowBulk] = useState<boolean>(false);
  //
  const [singleImageID, setSingleImageID] = useState<string | null>(null);
  const [showSingleImageModal, setShowSingleImageModal] =
    useState<boolean>(false);

  //For filters and keywords
  const [selectedFilters, setSelectFilters] = useState<
    Record<string, Record<string, string[]>>
  >({});

  const [andOrToggle, setAndOrToggle] = useState<AttributeToggle>("MIX");
  const [searchWord, setSearchWord] = useState<string>("");
  const [gptSearch, setGptSearch] = useState<string>("");
  const [btnSearchClicked, setBtnSearchClicked] = useState<boolean>(false);
  const [selectedSearchType, setSelectedSearchType] = useState<
    "smart" | "search" | "attributes"
  >("attributes");

  // state array containing the selected image ids from the Photo Container
  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setClientId(id || null);
    document.title = "Projects" + " | MDI SIM";
  }, [id]);

  return (
    <div id="dashboard" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          // client_Id={clientId}
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          setShowBulk={setShowBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectType="client"
          parsedClientID={id!}
        />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width-1-3@s uk-width-1-5@m">
        <Sidebar
          searchOptions={["all"]}
          clientID={id!}
          selectType="client"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectFilters}
          setSearchWord={setSearchWord}
          selectedSearchType={selectedSearchType}
          setSelectedSearchType={setSelectedSearchType}
          setGptSearch={setGptSearch}
          btnSearchClicked={btnSearchClicked}
          setBtnSearchClicked={setBtnSearchClicked}
          andOrToggle={andOrToggle}
          setAndOrToggle={setAndOrToggle}
        />
      </div>
      <div className="uk-width-2-3@s uk-width-4-5@m inner_container">
        {id ? (
          <PhotosContainer
            selectType="client"
            clientID={id}
            setShowBulk={setShowBulk}
            showBulk={showBulk}
            selectedBulkImageIds={selectedBulkImageIds}
            setBulkSelectedImageIds={setBulkSelectedImageIds}
            selectedImageIds={selectedImageIds}
            setSelectedImageIds={setSelectedImageIds}
            selectedFilters={selectedFilters}
            searchWord={searchWord}
            selectedSearchType={selectedSearchType}
            gptSearch={gptSearch}
            btnSearchClicked={btnSearchClicked}
            setSingleImageID={setSingleImageID}
            setShowSingleImageModal={setShowSingleImageModal}
            andOrToggle={andOrToggle}
          />
        ) : (
          <div
            id="single_image_page_component_container"
            className="uk-flex uk-grid no_image"
          >
            <div id="no_image_div">No Projects Found with ID: {id}</div>
          </div>
        )}
      </div>
      {showSingleImageModal && singleImageID !== null && id && (
        <SidePanelModal
          sourceID={id}
          showModal={showSingleImageModal}
          setShowModal={() => setShowSingleImageModal(false)}
          singleImageID={singleImageID}
          setSingleImageID={setSingleImageID}
          imageSource="client"
          location="right"
          contentType="singleImage"
          onClose={() => setShowSingleImageModal(false)}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
        />
      )}
    </div>
  );
};

export default ViewSingleClientPage;
