import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./clientsContainer.scss";
import "../DashboardContainer/landingPage.scss";
import { Link } from "react-router-dom";
import { ClientType } from "../../types";
import Client from "./client.component";

type ClientsContainerProps = {
  boardId: string | null;
  showBulk: boolean;
  clients: ClientType[];
  numberOfColumns: number;
  maxHeight: number;
  showPagination: boolean;
};

const ClientsContainer: React.FC<ClientsContainerProps> = ({
  clients,
  numberOfColumns,
  maxHeight,
  showPagination,
}) => {
  //Check if this is right
  const navigate = useNavigate();

  const handleClientCardClick = (client: ClientType) => {
    navigate("/client/" + client.client_id);
  };

  return (
    <>
      {clients.length === 0 ? (
        <div className="no-clients">
          <h2>No clients found</h2>
        </div>
      ) : (
        <div id="clients_container">
          <div
            id="clients_grid"
            className={`uk-grid uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-${numberOfColumns}@l`}
            style={{ maxHeight: maxHeight + "vh" }}
          >
            {clients.map((client, index) => (
              <div
                className="client_div_container"
                key={`client_id_container_${client.client_id}`}
              >
                <Link to={`/client/${client.client_id}`}>
                  <Client
                    client_id={client.client_id}
                    client={client}
                    index={index}
                    onClick={handleClientCardClick}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientsContainer;
